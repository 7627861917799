import './FullScreenPanel.css'
import { BigButton } from '../Commons'

export function FullScreenPanel(props) {
    return (
        <div class="fullscreen-blurred-background overflow-hidden fadeIn noScrollbar" onClick={props.fullScreenDismiss ? props.hidePanel : () => {}}>
            {props.fullScreenDismiss ? (<div></div>) : (<img class="close-xmark" src={require("./../../../assets/Icons/x.png")} onClick={props.hidePanel} />
            )}
            <div class="fullscreen-panel-content d-flex flex-column">
                <h2>{props.title}</h2>
                <div>
                    {props.component}
                </div>
            </div>
        </div>
    )
}