import './LifeCounter.css'
import React from "react"
import { useCallback, useEffect, useState } from "react";
import { useRef } from 'react';

function LifeCounter(props) {
    const survivorHP = props.lifepoints[0]
    const [survivor_variation, setSurvivor_variation] = useState(0)
    const hordeHP = props.lifepoints.length > 1 ? props.lifepoints[1] : 0
    const [horde_variation, setHorde_variation] = useState(0)
    const [horde_clickFeedback, setHorde_clickFeedback] = useState(false)
    const [survivor_clickFeedback, setSurvivor_clickFeedback] = useState(false)
    let horde_clickFeedback_timeOut = useRef(null)
    let survivor_clickFeedback_timeOut = useRef(null)
    let timeOut = useRef(null)
    const hordeHasLifepoints = props.options.hordeHasLifepoints


    function addLifeToHorde(amount) {
        let variation = horde_variation + amount
        setHorde_variation(variation)

        clearTimeout(timeOut.current)
        timeOut.current = setTimeout(() => { applyChanges(survivor_variation, variation); }, 1000);

        setHorde_clickFeedback("+")
        clearTimeout(horde_clickFeedback_timeOut.current)
        horde_clickFeedback_timeOut.current = setTimeout(() => { setHorde_clickFeedback(false) }, 100);
    }

    function removeLifeToHorde(amount) {
        let variation = horde_variation - amount
        if (hordeHP + horde_variation > 0) {
            setHorde_variation(variation)
        }

        clearTimeout(timeOut.current)
        timeOut.current = setTimeout(() => { applyChanges(survivor_variation, variation); }, 1000);

        setHorde_clickFeedback("-")
        clearTimeout(horde_clickFeedback_timeOut.current)
        horde_clickFeedback_timeOut.current = setTimeout(() => { setHorde_clickFeedback(false) }, 100);
    }

    function addLifeToSurvivors(amount) {
        let variation = survivor_variation + amount
        setSurvivor_variation(variation)

        clearTimeout(timeOut.current)
        timeOut.current = setTimeout(() => { applyChanges(variation, horde_variation); }, 1000);

        setSurvivor_clickFeedback("+")
        clearTimeout(survivor_clickFeedback_timeOut.current)
        survivor_clickFeedback_timeOut.current = setTimeout(() => { setSurvivor_clickFeedback(false) }, 100);
    }

    function removeLifeToSurvivors(amount) {
        let variation = survivor_variation - amount
        if (survivorHP + survivor_variation > 0) {
            setSurvivor_variation(variation)
        }

        clearTimeout(timeOut.current)
        timeOut.current = setTimeout(() => { applyChanges(variation, horde_variation); }, 1000);

        setSurvivor_clickFeedback("-")
        clearTimeout(survivor_clickFeedback_timeOut.current)
        survivor_clickFeedback_timeOut.current = setTimeout(() => { setSurvivor_clickFeedback(false) }, 100);
    }

    function applyChanges(survivorVariation, hordeVariation) {
        let horeHP_tmp = 0
        let survivorHP_tmp = 0

        if (survivor_variation < 0 && props.options.hordeheals) {
            horeHP_tmp = hordeHP + hordeVariation - survivorVariation
        } else {
            horeHP_tmp = hordeHP + hordeVariation
        }

        survivorHP_tmp = survivorHP + survivorVariation

        if (hordeHasLifepoints) {
            props.setLifepoints([survivorHP_tmp, horeHP_tmp])
        } else {
            props.setLifepoints([survivorHP_tmp])
        }

        setHorde_variation(0)
        setSurvivor_variation(0)
    }

    return (
        <div class={"lifeCounters blurred-background shadowed" + (hordeHasLifepoints ? "" : " hide-horde-lp")}>
            <div class={"lifeCounter hordeLife" + (horde_clickFeedback == "+" ? " adding" : "") + (horde_clickFeedback == "-" ? " removing" : "")}>
                <p class="name">Horde</p>
                <p class="variation">{horde_variation == 0 ? "" : horde_variation}</p>
                <p class="total">{hordeHP}</p>
                <LifeCounterInteractions func={{ add: addLifeToHorde, remove: removeLifeToHorde }} />
            </div>
            <div class={"lifeCounter playerLife" + (survivor_clickFeedback == "+" ? " adding" : "") + (survivor_clickFeedback == "-" ? " removing" : "")}>
                <p class="name">Survivors</p>
                <p class="variation">{survivor_variation == 0 ? "" : survivor_variation}</p>
                <p class="total">{survivorHP}</p>
                <LifeCounterInteractions func={{ add: addLifeToSurvivors, remove: removeLifeToSurvivors }} />
            </div>
        </div >
    )
}

function LifeCounterInteractions(props) {

    // This captures the starting position of the drag and is used to 
    // calculate the diff in positions of the cursor.
    const [startVal, setStartVal] = useState(0);

    // Start the drag to change operation when the mouse button is down.
    const onStart = useCallback(
        (event) => {
            console.log("Go")
            setStartVal(event.clientY);
        }
    );

    const onWheel = e => {
        if (e.deltaY > 0) {
            props.func.remove(1)
        } else if (e.deltaY < 0) {
            props.func.add(1)
        }
    };

    // We use document events to update and end the drag operation
    // because the mouse may not be present over the label during
    // the operation..
    useEffect(() => {
        // Only change the value if the drag was actually started.
        const onUpdate = (event) => {
            if (startVal) {
                let newValue = event.clientY
                if (newValue > startVal + 10) {
                    setStartVal(newValue);
                    props.func.remove(1)
                }
                else if (newValue < startVal - 10) {
                    setStartVal(newValue);
                    props.func.add(1)
                }
            }
        };

        // Stop the drag operation now.
        const onEnd = () => {
            setStartVal(0);
        };

        document.addEventListener("pointermove", onUpdate);
        document.addEventListener("pointerup", onEnd);

        return () => {
            document.removeEventListener("pointermove", onUpdate);
            document.removeEventListener("pointerup", onEnd);
        };
    }, [startVal]);

    return (
        <div class="life-upAndDown" onPointerDown={onStart}>
            <div class="life-up" onClick={() => { props.func.add(1) }} onWheel={onWheel} ></div>
            <div class="life-down" onClick={() => { props.func.remove(1) }} onWheel={onWheel} ></div>
        </div>
    )
}

export default LifeCounter