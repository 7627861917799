import GameReborn from "../GameReborn";
import { useParams } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { useEffect } from "react";
import { useRef } from 'react';

const supabase = createClient("https://gwgpryjgctjtyldxxvbj.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd3Z3ByeWpnY3RqdHlsZHh4dmJqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcyMjMyNzEsImV4cCI6MjAyMjc5OTI3MX0.mh5FD9z7bpUwR5QGZRMVvtnb-yjnosIn4_FDbHniMSE");
const { useState } = require("react")

export default function MultiplayerHostGame(props) {
    const [options, setOptions] = useState(false)
    const [selectedDeck, setSelectedDeck] = useState(false)
    const [isGameReady, setIsGameReady] = useState(false)
    const gameId = useRef("error")

    if (gameId.current == "error") {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        if (userData != undefined) {
            const uid = userData.uid
            gameId.current = uid
            console.log("Ini game with id " + uid);
        }
    }
/*
    async function iniGame(group_setup, board, deck, life, group_graveyard, group_newTurn, group_other) {
        console.log("----- CLOUD ACCESS - Ini Game -----")
        await supabase
            .from('Game')
            .upsert({ gameId: gameId.current, 
                    group_setup: group_setup, 
                    board: board,
                    deck: deck,
                    life: life,
                    group_graveyard: group_graveyard,
                    group_newTurn, group_newTurn,
                    group_other: group_other
                })
            .select()
    }*/

    async function iniGroup_setup(options, selectedDeck, tokens, deckSizeAtStart) {
        console.log("----- CLOUD ACCESS - Ini group_setup -----")
        if (gameId.current == "error") {
            console.log("Error, user has no id")
            return
        }
        const value = {
            options: options,
            selectedDeck: selectedDeck,
            tokens: tokens,
            deckSizeAtStart: deckSizeAtStart
        }
        await supabase
            .from('GroupSetupTable')
            .upsert({ gameId: gameId.current, group_setup: value })
            .select()
    }

    async function updateBoard(newBoard) {
        console.log("----- CLOUD ACCESS - Updating board -----")
        if (gameId.current == "error") {
            console.log("Error, user has no id")
            return
        }
        await supabase
            .from('BoardTable')
            .upsert({ gameId: gameId.current, board: newBoard })
            .select()
    }

    async function updateDeck(newDeck) {
        console.log("----- CLOUD ACCESS - Updating deck -----")
        if (gameId.current == "error") {
            console.log("Error, user has no id")
            return
        }
        await supabase
            .from('DeckTable')
            .upsert({ gameId: gameId.current, deck: newDeck })
            .select()
    }

    async function updateLifepoints(newLife) {
        console.log("----- CLOUD ACCESS - Updating lifepoints -----")
        if (gameId.current == "error") {
            console.log("Error, user has no id")
            return
        }
        await supabase
            .from('LifepointsTable')
            .upsert({ gameId: gameId.current, lifepoints: newLife })
            .select()
    }

    async function updateGroup_graveyard(newValue) {
        console.log("----- CLOUD ACCESS - Updating group_graveyard -----")
        if (gameId.current == "error") {
            console.log("Error, user has no id")
            return
        }
        await supabase
            .from('GroupGraveyardTable')
            .upsert({ gameId: gameId.current, group_graveyard: newValue })
            .select()
    }

    async function updateGroup_newTurn(newValue) {
        console.log("----- CLOUD ACCESS - Updating group_newTurn -----")
        if (gameId.current == "error") {
            console.log("Error, user has no id")
            return
        }
        await supabase
            .from('GroupNewTurnTable')
            .upsert({ gameId: gameId.current, group_newTurn: newValue })
            .select()
    }

    async function updateGroup_other(newValue) {
        console.log("----- CLOUD ACCESS - Updating group_other -----")
        if (gameId.current == "error") {
            console.log("Error, user has no id")
            return
        }
        await supabase
            .from('GroupOtherTable')
            .upsert({ gameId: gameId.current, group_other: newValue })
            .select()
    }

    return (
        <GameReborn selectedDeck={props.selectedDeck}
            setIsPlaying={props.setIsPlaying}
            options={props.options}
            cloudFunctions={{
                iniGroup_setup: iniGroup_setup,
                updateBoard: updateBoard,
                updateDeck: updateDeck,
                updateGroup_graveyard: updateGroup_graveyard,
                updateGroup_newTurn: updateGroup_newTurn,
                updateGroup_other: updateGroup_other,
                updateLifepoints: updateLifepoints
            }}

        >
        </GameReborn>
    )
}