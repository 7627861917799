import { BigButton, SelectableElement, WithHelp, ClickWithFeedBack } from './Commons.jsx'
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

function Options(props) {
    const options = props.options
    const functions = props.functions
    const [menu, setMenu] = useState(0)
    const [cardUrl, setCardUrl] = useState(options.backgroundImageUrl == undefined ? "" : options.backgroundImageUrl)
    let multiplayerLink = ""
    const hasPatreonAccess = patreonLvlForUid() != "patreon-0"

    function patreonLvlForUid() {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const patreonData = JSON.parse(sessionStorage.getItem("patreonData"))

        if (patreonData == undefined || userData == undefined) {
            props.functions.setPatreonLevel(0)
            return "patreon-0"
        }

        const uid = userData.uid
        multiplayerLink = "https://against-the-horde.com/play/" + uid

        const patreonsArray = patreonData.patreonsLvl

        if (patreonsArray == undefined) {
            props.functions.setPatreonLevel(0)
            return "patreon-0"
        }

        let patreonLvl = 0

        for (const i in patreonsArray) {
            if (patreonsArray[i].key == uid) {
                patreonLvl = patreonsArray[i].value
            }
        }

        props.functions.setPatreonLevel(patreonLvl)
        return ("patreon-" + patreonLvl)
    }

    function onChangeBackgroundImageUrl(event) {
        let cardUrlTmp = event.target.value
        setCardUrl(cardUrlTmp)
        functions.onChangeBackgroundImageUrl(cardUrlTmp)
    }

    function applyPreset(preset) {
        if (preset == 1) {
            let newOptions = {
                removeStrongPermanents: true,
                startWithPermanent: false,
                hordeHasLifepoints: false,
                hordeheals: false,
                startLife: 40,
                milestones: [false, false, false, false],
                deckSize: 75,
                tokenMultiplicator: 1,
                allowTokensHandAndGraveyard: false,
                backgroundColor: options.backgroundColor,
                foilEffect: options.foilEffect,
                alternateDrawMode: -1,
                safezoneSize: 1,
                backgroundImageUrl: options.backgroundImageUrl,
                drawPerTurn: 1,
                mulltiplayerEnabled: options.mulltiplayerEnabled
            }
            functions.setOptions(newOptions)
        } else if (preset == 2) {
            let newOptions = {
                removeStrongPermanents: true,
                startWithPermanent: false,
                hordeHasLifepoints: false,
                hordeheals: false,
                startLife: 40,
                milestones: [false, false, false, false],
                deckSize: 100,
                tokenMultiplicator: 1,
                allowTokensHandAndGraveyard: false,
                backgroundColor: options.backgroundColor,
                foilEffect: options.foilEffect,
                alternateDrawMode: -1,
                safezoneSize: 0.65,
                backgroundImageUrl: options.backgroundImageUrl,
                drawPerTurn: 1,
                mulltiplayerEnabled: options.mulltiplayerEnabled
            }
            functions.setOptions(newOptions)
        } else if (preset == 3) {
            let newOptions = {
                removeStrongPermanents: true,
                startWithPermanent: false,
                hordeHasLifepoints: false,
                hordeheals: false,
                startLife: 60,
                milestones: [false, false, false, false],
                deckSize: 100,
                tokenMultiplicator: 1,
                allowTokensHandAndGraveyard: false,
                backgroundColor: options.backgroundColor,
                foilEffect: options.foilEffect,
                alternateDrawMode: -1,
                safezoneSize: 1,
                backgroundImageUrl: options.backgroundImageUrl,
                drawPerTurn: 2,
                mulltiplayerEnabled: options.mulltiplayerEnabled
            }
            functions.setOptions(newOptions)
        } else if (preset == "4") {
            let newOptions = {
                removeStrongPermanents: true,
                startWithPermanent: false,
                hordeHasLifepoints: false,
                hordeheals: false,
                startLife: 80,
                milestones: [false, true, true, false],
                deckSize: 100,
                tokenMultiplicator: 2,
                allowTokensHandAndGraveyard: false,
                backgroundColor: options.backgroundColor,
                foilEffect: options.foilEffect,
                alternateDrawMode: -1,
                safezoneSize: 1,
                backgroundImageUrl: options.backgroundImageUrl,
                drawPerTurn: 2,
                mulltiplayerEnabled: options.mulltiplayerEnabled
            }
            functions.setOptions(newOptions)
        }
    }

    return (
        <div class="d-flex flex-column">
            <div class="options-categories d-flex flex-row justify-content-center">
                <h2 onClick={() => { setMenu(0) }} class={menu == 0 ? "selected" : "unselected"}>Options</h2>
                <h2 onClick={() => { setMenu(1) }} class={menu == 1 ? "selected" : "unselected"}>Others</h2>
            </div>
            <div class="options">
                {
                    menu == 0 ?
                        (
                            <span>
                                <h3>New player quick setup</h3>

                                <WithHelp component={
                                    <Form.Select className="form-select mt-0 card-type-select blurred-background" aria-label="Default select example"
                                        onChange={(event) => {
                                            applyPreset(event.target.value)
                                        }}>
                                        <option>Select option preset</option>
                                        <option value="1">1 player</option>
                                        <option value="2">2 players</option>
                                        <option value="3">3 players</option>
                                        <option value="4">4 players</option>
                                    </Form.Select>
                                } help="Replace the options below with predefined options recommended for a new players"></WithHelp>

                                <h3>All options</h3>

                                <p>Survivors starting life :</p>
                                <div>
                                    <SelectableElement text="20" onClick={() => { functions.onChangeStartLife(20) }} selected={options.startLife == 20} />
                                    <SelectableElement text="40" onClick={() => { functions.onChangeStartLife(40) }} selected={options.startLife == 40} />
                                    <SelectableElement text="60" onClick={() => { functions.onChangeStartLife(60) }} selected={options.startLife == 60} />
                                    <SelectableElement text="80" onClick={() => { functions.onChangeStartLife(80) }} selected={options.startLife == 80} />
                                </div>

                                <WithHelp component={
                                    <p>Deck size :</p>
                                } help="Increase or reduce the size of the Horde deck"></WithHelp>
                                <div>
                                    <SelectableElement text="50%" onClick={() => { functions.onChangeDeckSize(50) }} selected={options.deckSize == 50} />
                                    <SelectableElement text="75%" onClick={() => { functions.onChangeDeckSize(75) }} selected={options.deckSize == 75} />
                                    <SelectableElement text="100%" onClick={() => { functions.onChangeDeckSize(100) }} selected={options.deckSize == 100} />
                                    <SelectableElement text="200%" onClick={() => { functions.onChangeDeckSize(200) }} selected={options.deckSize == 200} />
                                </div>
                                <WithHelp component={
                                    <label><input type="checkbox" checked={options.removeStrongPermanents} onChange={functions.onChangeRemoveStrongPermanents} />Evenly distribute the number of tokens and remove strong cards during the first turns</label>
                                } help="Reduce randomness by making a 'Safezone' at the beginning of the Horde deck. This zone will have tokens evenly dispatched between nontoken cards and won't have any card from the 'Lategame' section of the deck"></WithHelp>
                                {options.removeStrongPermanents ? (
                                    <span class="sub-option">
                                        <p>Safe zone size :</p>
                                        <div>
                                            <SelectableElement text="Default" onClick={() => { functions.onChangeSafezoneSize(1) }} selected={options.safezoneSize == 1} />
                                            <SelectableElement text="Reduced" onClick={() => { functions.onChangeSafezoneSize(0.65) }} selected={options.safezoneSize == 0.65} />
                                        </div>
                                    </span>
                                )
                                    : (<h4></h4>)
                                }

                                <WithHelp component={
                                    <p>Horde draw per turn :</p>
                                } help="Number of times you have to make the Horde draw each turn. Recommend at least 2 for 3+ players"></WithHelp>
                                <div>
                                    <SelectableElement text="1" onClick={() => { functions.onChangeDrawPerTurn(1) }} selected={options.drawPerTurn == 1} />
                                    <SelectableElement text="2" onClick={() => { functions.onChangeDrawPerTurn(2) }} selected={options.drawPerTurn == 2} />
                                    <SelectableElement text="3" onClick={() => { functions.onChangeDrawPerTurn(3) }} selected={options.drawPerTurn == 3} />
                                </div>

                                {options.alternateMilestones ? (<h4></h4>) : (
                                    <span>
                                        <WithHelp component={
                                            <p>Cast a random permanent when reaching milestone :</p>
                                        } help="Cast a random card from the 'Milestones' section of the deck when the Horde library has lost the selected percentages of its size"></WithHelp>
                                        <div>
                                            <SelectableElement text="25%" onClick={() => { functions.onChangeMilestones(0) }} selected={options.milestones[0]} />
                                            <SelectableElement text="50%" onClick={() => { functions.onChangeMilestones(1) }} selected={options.milestones[1]} />
                                            <SelectableElement text="75%" onClick={() => { functions.onChangeMilestones(2) }} selected={options.milestones[2]} />
                                            <SelectableElement text="100%" onClick={() => { functions.onChangeMilestones(3) }} selected={options.milestones[3]} />
                                        </div>
                                    </span>
                                )}


                                <WithHelp component={
                                    <p>Token multiplicator :</p>
                                } help="Multiply the number of tokens in the deck making the Horde play more tokens each turns"></WithHelp>
                                <div>
                                    <SelectableElement text="x1" onClick={() => { functions.onChangeTokenMultiplicator(1) }} selected={options.tokenMultiplicator == 1} />
                                    <SelectableElement text="x2" onClick={() => { functions.onChangeTokenMultiplicator(2) }} selected={options.tokenMultiplicator == 2} />
                                    <SelectableElement text="x3" onClick={() => { functions.onChangeTokenMultiplicator(3) }} selected={options.tokenMultiplicator == 3} />
                                    <SelectableElement text="x4" onClick={() => { functions.onChangeTokenMultiplicator(4) }} selected={options.tokenMultiplicator == 4} />
                                </div>

                                <h3>Optional rules</h3>
                                <WithHelp component={
                                    <label><input type="checkbox" checked={options.hordeHasLifepoints} onChange={functions.onChangeHordeHasLifepoints} />Horde has lifepoints</label>
                                } help="The Horde has lifepoints and will only mill his library once it has no more lifepoints"></WithHelp>


                                {options.hordeHasLifepoints ?
                                    (<WithHelp component={
                                        <span class="sub-option"><label><input type="checkbox" checked={options.hordeheals} onChange={functions.onChangeHordeHeals} />Horde heals when survivors lose life</label></span>
                                    } help="For each lifepoints lost by Survivors, the Horde will heals the same amount"></WithHelp>
                                    ) : (<h4></h4>)
                                }

                                <WithHelp component={
                                    <label><input type="checkbox" checked={options.allowTokensHandAndGraveyard} onChange={functions.onChangeAllowTokensHandAndGraveyard} />Allow tokens to be sent to the hand, graveyard and exile</label>
                                } help="If enabled, token are treated like regular permanent card and will not be removed from the game when leaving the battlefield"></WithHelp>
                                <WithHelp component={
                                    <label><input type="checkbox" checked={options.alternateDrawMode != -1} onChange={functions.onChangeAlternateDrawMode} />Use alternative draw mode</label>
                                } help="Draw a fixed number of cards each turn instead of revealing until one non token"></WithHelp>
                                <WithHelp component={
                                    <label><input type="checkbox" checked={options.startWithPermanent} onChange={functions.onChangeStartWithPermanents} />Horde starts with a random permanent instead of all (only enable this if the decks tells you to)</label>
                                } help="The Horde starts with every permanent in its 'Start' section. If enabled, the Horde will start with one them chosen randomly instead"></WithHelp>
                                <WithHelp component={
                                    <label><input type="checkbox" checked={options.alternateMilestones} onChange={functions.onChangeAlternateMilestones} />Milestones acts like a secondary deck</label>
                                } help="Cards in the Milestones section of the deck will form a separate deck to draw from at any time"></WithHelp>
                                {options.alternateMilestones ?
                                    (<WithHelp component={
                                        <span class="sub-option"><label><input type="checkbox" checked={options.alternateMilestones_shuffle} onChange={functions.onChangeAlternateMilestones_shuffle} />Shuffle milestones deck</label></span>
                                    } help="The Milestones deck will be shuffled at the beginning of the game"></WithHelp>
                                    ) : (<h4></h4>)
                                }
                            </span>
                        ) : (
                            <span>
                                <span>
                                    <h3>Visual</h3>

                                    <p>Background color :</p>

                                    <div>
                                        <SelectableElement text={
                                            <span class="background-gradient-1 background-choice"></span>
                                        } onClick={() => { functions.onChangeBackgroundColor(1) }} selected={options.backgroundColor == 1} />
                                        <SelectableElement text={
                                            <span class="background-gradient-2 background-choice"></span>
                                        } onClick={() => { functions.onChangeBackgroundColor(2) }} selected={options.backgroundColor == 2} />
                                        <SelectableElement text={
                                            <span class="background-gradient-3 background-choice"></span>
                                        } onClick={() => { functions.onChangeBackgroundColor(3) }} selected={options.backgroundColor == 3} />
                                        <SelectableElement text={
                                            <span class="background-gradient-4 background-choice"></span>
                                        } onClick={() => { functions.onChangeBackgroundColor(4) }} selected={options.backgroundColor == 4} />
                                    </div>
                                    <div>
                                        <SelectableElement text={
                                            <span class="background-gradient-5 background-choice"></span>
                                        } onClick={() => { functions.onChangeBackgroundColor(5) }} selected={options.backgroundColor == 5} />
                                        <SelectableElement text={
                                            <span class="background-gradient-6 background-choice"></span>
                                        } onClick={() => { functions.onChangeBackgroundColor(6) }} selected={options.backgroundColor == 6} />
                                        <SelectableElement text={
                                            <span class="background-gradient-7 background-choice"></span>
                                        } onClick={() => { functions.onChangeBackgroundColor(7) }} selected={options.backgroundColor == 7} />
                                    </div>
                                    <WithHelp component={
                                        <p>Background image link :</p>
                                    } help="Not all websites will allow you to use an image hosted by them. If it doesn't show a preview below, try another website."></WithHelp>
                                    <input placeholder="https://MY-IMAGE" onChange={onChangeBackgroundImageUrl.bind(this)} defaultValue={options.backgroundImageUrl} type="text" id="exampleForm.ControlTextarea1" class="blurred-background form-control w-100" />
                                    {
                                        cardUrl.length == 0 ? (<div class="background-image-preview"></div>) : (<img class="background-image-preview" src={cardUrl} />)
                                    }

                                    <h3>Multiplayer - Patreons only</h3>

                                    <span class={hasPatreonAccess ? "" : "patreon-restricted"}>
                                        <p>Please disable this option when playing without using it.</p>
                                        <label><input type="checkbox" checked={options.mulltiplayerEnabled} onChange={() => {
                                            if (hasPatreonAccess) {
                                                functions.onChangeMultiplayer(!options.mulltiplayerEnabled)
                                            } else {
                                                functions.onChangeMultiplayer(false)
                                            }
                                        }} />
                                            Enable multiplayer
                                        </label>
                                        <p>Players will be able to watch your game through the link below. Note that you will have to take all actions as other players won't be allowed to interact with the game.</p>
                                        <p>Click the link to copy it to the clipboard.</p>
                                        {options.mulltiplayerEnabled ?
                                            (<ClickWithFeedBack component={<p onClick={() => { navigator.clipboard.writeText(multiplayerLink); }} class="multiplayer-link blurred-background">{multiplayerLink}</p>}></ClickWithFeedBack>) : (<div></div>)
                                        }

                                    </span>

                                    <h3>Foil - Patreons only</h3>

                                    <span class={hasPatreonAccess ? "" : "patreon-restricted"}>
                                        <p>Foil effect :</p>
                                        <label><input type="checkbox" checked={options.foilEffect[0]} onChange={() => {
                                            if (!hasPatreonAccess) { return }
                                            functions.onChangeFoilEffect(
                                                [!options.foilEffect[0], options.foilEffect[1], options.foilEffect[2]]
                                            )
                                        }} />
                                            Foil effects on all strong cards
                                        </label>
                                        <label><input type="checkbox" checked={options.foilEffect[1]} onChange={() => {
                                            if (!hasPatreonAccess) { return }
                                            functions.onChangeFoilEffect(
                                                [options.foilEffect[0], !options.foilEffect[1], options.foilEffect[2]]
                                            )
                                        }} />
                                            Foil effects on all non token cards
                                        </label>
                                        <label><input type="checkbox" checked={options.foilEffect[2]} onChange={() => {
                                            if (!hasPatreonAccess) { return }
                                            functions.onChangeFoilEffect(
                                                [options.foilEffect[0], options.foilEffect[1], !options.foilEffect[2]]
                                            )
                                        }} />
                                            Foil effects on all token cards
                                        </label>
                                    </span>

                                </span>
                            </span>
                        )
                }
            </div>
        </div>
    )
}

export default Options