import { useNavigate } from 'react-router-dom'
import './HalfScreenPanel.css'

export function HalfScreenPanel(props) {
    let navigate = useNavigate()
    return (
        <div class="noScrollbar half-screen-panel">
            {props.hidePanel == "/yourdecks" ? 
                (<img class="close-xmark" src={require("./../../../assets/Icons/x.png")} onClick={() => {
                    navigate(props.hidePanel)
                }} />) :
                (<img class="close-xmark" src={require("./../../../assets/Icons/x.png")} onClick={props.hidePanel} />)
            }
            <div class="row h-100">
                <div className='col-md-7 container p-5'>
                    {props.component}
                </div>
                <div className='col-md-5 landscape-only p-0'>
                    <img src={require('./../../../assets/' + props.imageName)} class="w-100" />
                    <p class="copyright">{"Art by " + props.copyright + " ©Wizards of the Coast LLC"}</p>
                </div>
            </div>
        </div>
    )
}