import { useEffect } from "react";
import React from "react"
import "./PopUpMenu.css"

const { useState } = require("react")

export default function PopUpMenu(props) {
    const [enabled, setEnabled] = useState(false)
    const [library_enabled, setLibary_enabled] = useState(false)
    const [counter_enabled, setCounter_enabled] = useState(false)
    const [counterAll_enabled, setCounterAll_enabled] = useState(false)

    return (
        <div class="pop-up-menu">
            <div class={(enabled ? "enabled" : "") + " default-button"}
                onClick={() => {
                    setEnabled(!enabled)
                    setLibary_enabled(false)
                    setCounter_enabled(false)
                    setCounterAll_enabled(false)
                }}><p class="m-0">{enabled ? "⨯" : "⋮"}</p></div>

            <div class={enabled ? "enabled" : "disabled"}>
                <div class="pop-up-background"
                    onClick={() => {
                        setEnabled(false)
                    }}
                ></div>
                <div class="pop-up-menu-buttons blurred-background shadowed d-flex flex-column">
                    <PopUpMenuButton text="Exile" function={props.exile}></PopUpMenuButton>
                    <div class="spacer"></div>
                    <PopUpMenuButton text="Return to hand" function={props.returnToHand}></PopUpMenuButton>
                    <div class="spacer"></div>
                    {
                        !library_enabled ? (<PopUpMenuButton text="Library" function={() => {setLibary_enabled(true)}}></PopUpMenuButton>)
                        : (
                            <div class="d-flex flex-row">
                                <PopUpMenuIconButton iconName="dessus" center={true} function={props.top}></PopUpMenuIconButton>
                                <PopUpMenuIconButton iconName="dessous" center={true} function={props.bottom}></PopUpMenuIconButton>
                                <PopUpMenuIconButton iconName="aleatoire" center={true} function={props.shuffle}></PopUpMenuIconButton>
                            </div>
                        )
                    }
                    <div class="spacer"></div>
                    {
                        !counter_enabled ? (<PopUpMenuButton text="Counters" function={() => {setCounter_enabled(true)}}></PopUpMenuButton>)
                        : (
                            <div class="d-flex flex-row">
                                <PopUpMenuButton text="-" center={true} function={props.removeCounter}></PopUpMenuButton>
                                <PopUpMenuButton text="+" center={true} function={props.addCounter}></PopUpMenuButton>
                            </div>
                        )
                    }
                    {
                        !counterAll_enabled ? (<PopUpMenuButton text="Counters all" function={() => {setCounterAll_enabled(true)}}></PopUpMenuButton>)
                        : (
                            <div class="d-flex flex-row">
                                <PopUpMenuButton text="-" center={true} function={props.removeCounterAll}></PopUpMenuButton>
                                <PopUpMenuButton text="+" center={true} function={props.addCounterAll}></PopUpMenuButton>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )

    function PopUpMenuButton(props) {
        return (
            <div class={"pop-up-menu-button d-flex" + (props.center ? " justify-content-center" : " justify-content-start")} onClick={props.function}>
                <p>{props.text}</p>
            </div>
        )
    }

    function PopUpMenuIconButton(props) {
        return (
            <div class={"pop-up-menu-button d-flex" + (props.center ? " justify-content-center" : " justify-content-start")} onClick={props.function}>
                <img src={require("../../../../assets/Icons/" + props.iconName + ".png")} />
            </div>
        )
    }
}