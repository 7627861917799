import logo from './assets/appIcon.png';
import Horde from './Components/Horde'
import './App.css';

function App() {
  return (
    <div className="App">
      <script src=''></script>
      <header className="App-header">
        {/*
                <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>

        <DeckEditor />
<Horde />
        */}


      </header>
    </div>
  );
}

export default App;
