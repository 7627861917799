import React from "react";
import { useParams } from "react-router-dom";
import DeckBrowser from "./DeckBrowser";

export function DeckBrowserLink(props) {
    const { query } = useParams();

    return (
        <DeckBrowser searchQuery={query}/>
    )
}

export function DeckBrowserPublicLink(props) {
    const { query } = useParams();

    return (
        <DeckBrowser searchQuery={"PUBLIC_ORDERBY_" + query}/>
    )
}

export default DeckBrowserLink