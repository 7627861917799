// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { FieldPath, getFirestore } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDaRSvL9OAVSiK1d5-cbwAf4aIOU5L1pMc",
  authDomain: "against-the-horde-e598e.firebaseapp.com",
  projectId: "against-the-horde-e598e",
  storageBucket: "against-the-horde-e598e.appspot.com",
  messagingSenderId: "787245630983",
  appId: "1:787245630983:web:98ee8f7dc3c75d7a7b8ca2"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);