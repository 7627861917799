import ScrollContainer from 'react-indiana-drag-scroll'
import React, { useEffect, useState } from "react";
import './HomePage.css'
import './Options.css'
import { BigButton, DeckArt, Footer } from './../Commons/Commons.jsx'
import ReactDOM from "react-dom/client";
import DeckEditor from '../DeckEditor/DeckEditor.jsx';
import Options from './../Commons/Options.jsx';
import { getDoc, collection, doc, getDocs, query, where, documentId, setDoc } from "firebase/firestore";
import { db, auth } from '../../firebase.js';
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import NewNavBar from './NewNavBar.jsx';
import PlayablePanel from '../Commons/PlayablePanel/PlayablePanel.jsx';
import { Link } from "react-router-dom"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const { DECKS } = require("../../utils/DB.js");


class HomePage extends React.Component {
    constructor(props) {
        super(props)

        var decks = DECKS

        const userData = JSON.parse(sessionStorage.getItem("userData"))

        if (userData == undefined || userData.decks == undefined || userData.shouldUpdateDecks) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.iniUserDecks(user.uid)
                }
            })
        } else {
            decks = userData.decks
        }

        let oldSelectedDeck = "INTRO"
        /*
        if (props.selectedDeck != "INTRO") {
            for (let i = 0; i < decks.length; i++) {
                if (decks[i].deckId == props.selectedDeck.deckId) {
                    oldSelectedDeck = decks[i]
                }
            }
        }
*/
        this.state = {
            selectedDeck: oldSelectedDeck,
            artistName: "Greg Opalinski",
            customDeckListIsValid: false,
            userDecks: decks,
            userDecksLoaded: false,
            userLoggedIn: userData != undefined
        };

        if (userData != undefined) {
            this.setState({ userLoggedIn: true })
        }
    }

    iniUserDecks = async (uid) => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))

        if (userData == undefined || userData.decks == undefined || userData.shouldUpdateDecks) {
            if (userData != undefined && userData.userCollection != undefined) {
                console.log("Collection already downloaded")
                this.iniDecksWithCollection(userData.userCollection, uid)
            } else {
                await getDoc(doc(db, "usersCollection", uid))
                    .then(async (userDeckIdsSnapshot) => {
                        console.log("CALL - iniUserDecks - getCollection")
                        const decksCollection = userDeckIdsSnapshot.data().collection
                        this.iniDecksWithCollection(decksCollection, uid)
                    })
            }
        } else {
            console.log("Deck deja dl")
            this.setState({ userDecks: userData.decks })
        }
    }

    iniDecksWithCollection = async (decksCollection, uid) => {
        const batches = [];
        const decksCollectionTmp = [...decksCollection]

        while (decksCollectionTmp.length) {
            batches.push(decksCollectionTmp.splice(0, 20));
        }

        Promise.all(batches.map(batch => {
            return new Promise((resolve, reject) => {

                const documents = [];

                getDocs(query(collection(db, "decks"), where(documentId(), "in", batch)))
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            documents.push({ ...doc.data(), deckId: doc.id });

                        });
                        console.log(documents)
                        resolve(documents);
                    }).catch((error) => {
                        reject(error);
                    });
            });
        }))
            .then(async decksDocs => {
                // Les résultats de chaque appel sont disponibles ici
                let decksData = decksDocs.flat()
                console.log(decksCollection)
                console.log("CALL - iniUserDecks - getDecks")

                // Order decks
                let decksDataOrdered = []
                decksCollection.forEach(deckId => {
                    decksData.forEach(deck => {
                        if (deckId == deck.deckId) {
                            decksDataOrdered.push(deck)
                        }
                    })
                })

                this.setState({ userDecks: decksDataOrdered })
                this.state.userDecksLoaded = true

                // If some decks have been removed, remove them from collection
                let collectionWithoutMissingDecks = []
                decksDataOrdered.forEach(deck => {
                    collectionWithoutMissingDecks.push(deck.deckId)
                })

                if (collectionWithoutMissingDecks.length < decksCollection.length) {
                    console.log("Decks are missing, removing them from collection")
                    console.log(collectionWithoutMissingDecks)
                    await setDoc(doc(db, "usersCollection", uid), {
                        collection: collectionWithoutMissingDecks
                    });
                }

                sessionStorage.setItem("userData", JSON.stringify({
                    uid: uid,
                    decks: decksDataOrdered,
                    shouldUpdateDecks: false,
                    userCollection: decksCollection
                }))
            })
            .catch(error => {
                // En cas d'erreur lors de l'un des appels
                console.error(error);
            });
    }

    selectDeck = (deck) => {
        console.log("Selecting deck " + deck.name)
        this.setState({ selectedDeck: deck })
        //this.props.setSelectedDeck(deck)

        if (deck == "INTRO") {
            this.setState({ artistName: "Greg Opalinski" })
        } else {
            if (deck.image) {
                this.setState({ artistName: deck.artistName })
            } else {
                this.setState({ artistName: "" })
            }
        }
    }

    openDeckEditor = () => {
        const myWindow = window.open('', '_blank');
        const root = ReactDOM.createRoot(document.getElementById("root"));
        root.render(<DeckEditor />, myWindow.document.body);

        myWindow.document.close();
        myWindow.focus();
        myWindow.close();
    }

    render() {
        return (
            <div id="HomePage" class="container-fluid p-0 text-white">
            <PlayablePanel selectedDeck={this.state.selectedDeck} setSelectedDeck={this.selectDeck} component={
                <div>
                    <NewNavBar selected="Home" />
                    <div class={"container-fluid text-center main-section p-0 d-flex align-items-middle" + (this.state.userLoggedIn ? " user-logged-in" : "")}>
                            <div class="background-wrapper">
                            <img src={require('./../../assets/img_intro.jpg')} class="background-image" />  
                            </div>
                                      
                        <div class="container d-flex align-items-start flex-column intro-text mt-auto mb-5 p-0 px-2 ">
                            <h1 class="text-uppercase-ornot">Against the Horde</h1>
                            <label class="mt-auto text-start">The horde is a cooperative way to play tabletop Magic the Gathering for 1 to 4 players against an automated opponent.</label>
                            <h2>What is Against the horde ?</h2>
                            <p class="text-start">Against the Horde is an app that allows players to easily enjoy the horde format. Take your favorite deck, press the play button and try to survive !</p>
                            <p class="text-start">Each player needs a real Magic the Gathering deck, this app only handle the role of the automated opponent.</p>
                            <p class="text-start">Don't know how to play ? Press the 'Help' button when playing to read the rules and learn how to use the app.</p>
                        </div>
                    </div>
                    <div class="container">
                        <h1 class="text-start your-decks-title mt-4">Your decks</h1>
                        <Row className=' d-flex justify-content-center container mx-auto p-0 mb-5'>
                            {this.state.userDecks.map(function (deck, i) {
                                return (
                                    <DeckPicker_Deck deck={deck} key={i} selectDeck={this.selectDeck} />
                                )
                            }, this)}
                        </Row>
                    </div>
                    <Footer></Footer>
                </div>
            } />
            </div>
        )
    }
}

function DeckPicker_Deck(props) {
    const deck = props.deck

    return (
        <Col md={6} lg={4} xxl={3}>
            <button class="deckPicker deck-hover-animated shadowed" onClick={() => { props.selectDeck(deck) }}>
                {deck.image ?
                    (<img src={require('./../../assets/' + deck.image)} />) :
                    (<DeckArt artId={deck.artId} />)
                }
                <div><p>{deck.name}</p></div>
            </button>
        </Col>
    )
}

export default HomePage