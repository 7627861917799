import { DECKS } from '../../utils/DB'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useEffect } from "react";
import './GameReborn.css'
import React from "react"
import LifeCounter from './LifeCounter/LifeCounter.jsx'
import { Button, BigButton, CardIMG } from '../Commons/Commons.jsx'
import { CustomScrollContainer } from '../Commons/CustomScrollContainer'
import { type } from '@testing-library/user-event/dist/type';
import useGoogleSheets from 'use-google-sheets';
import { FullScreenPanel } from '../Commons/FullScreenPanel/FullScreenPanel.jsx';
import PopUpMenu from './Small/PopUpMenu/PopUpMenu.jsx';
const { useState } = require("react")


export default function GameReborn(props) {
    // Cloud synced

    const [board, setBoard] = useState([])

    const [deck, setDeck] = useState([])

    const [lifepoints, setLifepoints] = useState([props.options.startLife, 20])

    // group_graveyard
    const [graveyard, setGraveyard] = useState([])
    const [lastMilledToken, setLastMilledToken] = useState(false)
    const [millCount, setMillCount] = useState(0)

    // group_setup
    const [deckSizeAtStart, setDeckSizeAtStart] = useState(0)
    const [tokens, setTokens] = useState([])
    const [selectedDeck, setSelectedDeck] = useState(props.selectedDeck)
    const [options, setOptions] = useState(props.options)

    // group_other
    const [hand, setHand] = useState([])
    const [showTopCard, setShowTopCard] = useState(false)
    const [attackers, setAttackers] = useState([])
    const [showAttackers, setShowAttackers] = useState(false)

    // group_newTurn
    const [turnNumber, setTurnNumber] = useState(0)
    const [cardsToCast_fromLibrary, setCardsToCast_fromLibrary] = useState([])
    const [cardsToCast_fromGraveyard, setCardsToCast_fromGraveyard] = useState([])
    const [cardsToCast_fromHand, setCardsToCast_fromHand] = useState([])
    const [cardsToCast_milestone, setCardsToCast_milestone] = useState([])


    // Local only

    const [gameStarted, setGameStarted] = useState(false)
    const [showGraveyard, setShowGraveyard] = useState(false)
    const [shouldResetAttackers, setShouldResetAttackersk] = useState(true)
    const [cardPressMode, setCardPressMode] = useState(0)
    const [showHelp, setShowHelp] = useState(false)
    const [cardsTooStrong, setCardsTooStrong] = useState([])        // UNUSED ?
    const [weakCards, setWeakCards] = useState([])                  // UNUSED ?
    const [strongCards, setStrongCards] = useState([])
    const [milestones, setMilestones] = useState(props.options.milestones.slice())
    const [cardIdsToReplace, setCardIdsToReplace] = useState([])    // OBSOLETE
    const [alternateDrawValue, setAlternateDrawValue] = useState(props.options.alternateDrawMode)
    const [drawPerTurn, setDrawPerTurn] = useState(props.options.drawPerTurn)
    const [drawThisTurn, setDrawThisTurn] = useState(0)

    const isSpectating = props.cloudValues ? true : false

    // A ajouter
    const [showExile, setShowExile] = useState(false)
    const [exile, setExile] = useState([])
    const [showBoardWipeConfirm, setShowBoardWipeConfirm] = useState(false)

    const [gameStatesArray, setGameStatesArray] = useState([])

    //setGameStarted(true)
    // A VOIR
    if (!gameStarted) {
        iniDeckList()
    }
    sessionStorage.removeItem("alternativeTokensData")

    // Spectators hook

    useEffect(() => {
        if (props.cloudValues) {
            console.log("Update recieved from host")
            const data = props.cloudValues
            setBoard(data.board)

            setDeck(data.deck)

            setLifepoints(data.lifepoints)

            setGraveyard(data.group_graveyard.graveyard)
            setLastMilledToken(data.group_graveyard.lastMilledToken)
            setMillCount(data.group_graveyard.millCount)

            setTurnNumber(data.group_newTurn.turnNumber)
            setCardsToCast_fromLibrary(data.group_newTurn.cardsToCast_fromLibrary)
            setCardsToCast_fromGraveyard(data.group_newTurn.cardsToCast_fromGraveyard)
            setCardsToCast_fromHand(data.group_newTurn.cardsToCast_fromHand)
            setCardsToCast_milestone(data.group_newTurn.cardsToCast_milestone)

            setHand(data.group_other.hand)
            setShowTopCard(data.group_other.showTopCard)
            setAttackers(data.group_other.attackers)
            setShowAttackers(data.group_other.showAttackers)

            setTokens(data.group_setup.tokens)
            setOptions(data.group_setup.options)
            setSelectedDeck(data.group_setup.selectedDeck)
            setDeckSizeAtStart(data.group_setup.deckSizeAtStart)
        }
    }, [props.cloudValues]);

    // Local hooks

    useEffect(() => {
        if (props.cloudFunctions) {
            props.cloudFunctions.updateBoard(board)
        }
    }, [board]);

    useEffect(() => {
        if (props.cloudFunctions) {
            props.cloudFunctions.updateDeck(deck)
        }
    }, [deck]);

    useEffect(() => {
        if (props.cloudFunctions) {
            props.cloudFunctions.updateLifepoints(lifepoints)
        }
    }, [lifepoints]);

    useEffect(() => {
        if (props.cloudFunctions) {
            props.cloudFunctions.updateGroup_graveyard({
                graveyard: graveyard,
                lastMilledToken: lastMilledToken,
                millCount: millCount
            })
        }
    }, [graveyard, lastMilledToken, millCount]);

    useEffect(() => {
        if (props.cloudFunctions) {
            props.cloudFunctions.updateGroup_newTurn({
                turnNumber: turnNumber,
                cardsToCast_fromLibrary: cardsToCast_fromLibrary,
                cardsToCast_fromGraveyard: cardsToCast_fromGraveyard,
                cardsToCast_fromHand: cardsToCast_fromHand,
                cardsToCast_milestone: cardsToCast_milestone
            })
        }
    }, [turnNumber, cardsToCast_fromGraveyard, cardsToCast_fromHand, cardsToCast_fromLibrary, cardsToCast_milestone]);

    useEffect(() => {
        if (props.cloudFunctions) {
            props.cloudFunctions.updateGroup_other({
                hand: hand,
                showTopCard: showTopCard,
                attackers: attackers,
                showAttackers: showAttackers
            })
        }
    }, [hand, showTopCard, attackers, showAttackers]);

    // Functions

    function iniDeckListWithText(text) {
        setGameStarted(true)
        let cardsTooStrong_tmp = []
        let weakCards_tmp = []
        let strongCards_tmp = []
        let tokens_tmp = []
        let deck_tmp = []

        let currentStep = 0;
        const deckList = text.split('\n');
        for (var i = 0; i < deckList.length; i++) {

            //  Change current step
            if (deckList[i].includes("## Horde Deck ##")) {
                currentStep = 1
            } else if (deckList[i].includes("## Too Strong ##")) {
                currentStep = 2
            } else if (deckList[i].includes("## Available Tokens ##")) {
                currentStep = 3
            } else if (deckList[i].includes("## Weak Permanents ##")) {
                currentStep = 4
            } else if (deckList[i].includes("## Powerfull Permanents ##")) {
                currentStep = 5
            } else if (deckList[i].trim() != "") {

                console.log("Parsing " + deckList[i])
                var newCardLine = deckList[i].split(' ')


                let cardFlashback = false
                let castFromGraveyardThenExile = false
                let cardDefender = false
                // If old or new system
                if (newCardLine[3] == "YES" || newCardLine[3] == "NO") {
                    cardFlashback = newCardLine[3] == "YES"
                    castFromGraveyardThenExile = true
                } else {
                    cardFlashback = newCardLine[3].includes('f')
                    castFromGraveyardThenExile = !newCardLine[3].includes('e')
                    cardDefender = newCardLine[3].includes('d')
                }
                const cardCapacities = {
                    castFromGraveyard: cardFlashback,
                    castFromGraveyardThenExile: castFromGraveyardThenExile,
                    cantAttackOrBlock: cardDefender,
                    isFoil: false
                }

                let card = newCard(newCardLine[0], newCardLine[newCardLine.length - 1], newCardLine[2], cardCapacities, 0, newCardLine.slice(4, newCardLine.length - 2).join(' '))

                // Add card to deck
                if (currentStep == 1) {
                    const res = addCardToDeck(card, newCardLine[newCardLine.length - 2], deck_tmp, tokens_tmp)
                    deck_tmp = res[0]
                    tokens_tmp = res[1]
                } else if (currentStep == 2) {
                    card.count = 1
                    cardsTooStrong_tmp.push(card)
                } else if (currentStep == 3) {
                    tokens_tmp = addTokenToTokenRow(card, newCardLine[newCardLine.length - 2], tokens_tmp)
                } else if (currentStep == 4) {
                    // Not only 1 card count anymore
                    /*card.count = 1*/
                    console.log("->" + card.count)
                    weakCards_tmp.push(card)
                } else if (currentStep == 5) {
                    for (var n = 0; n < card.count; n++) {
                        let tmpCard = recreateCard(card)
                        tmpCard.count = 1
                        strongCards_tmp.push(tmpCard)
                    }
                }
            }
        }

        console.log("Parsing complete")


        // Reduce or increase library size
        if (options.deckSize <= 100) {
            let cardsToKeep = Math.floor(deck_tmp.length * (options.deckSize / 100))
            console.log("Keeping " + cardsToKeep)
            deck_tmp = shuffle(deck_tmp)
            deck_tmp.splice(0, deck_tmp.length - cardsToKeep)
        } else {
            deck_tmp = deck_tmp.concat(deck_tmp)
        }

        // No strong cards in the first cards
        if (options.removeStrongPermanents) {
            let tmpDeck = [...deck_tmp]
            let difficulty = 1
            let safeZoneSize = Math.floor(0.20 * tmpDeck.length * options.safezoneSize)
            console.log("SAFE " + safeZoneSize)

            // We count the number of tokens and spells
            let nbrOfSpells = 0
            let nbrOfTokens = 0
            for (var i = 0; i < tmpDeck.length; i++) {
                if (tmpDeck[i].type == "token") {
                    nbrOfTokens += 1
                } else {
                    nbrOfSpells += 1
                }
            }

            let tokensRatioInDeck = nbrOfTokens / tmpDeck.length
            let averageNumberOfTokens = safeZoneSize * tokensRatioInDeck
            //let n = safeZoneSize - averageNbrOfToken // Number of real cards you want to draw on average before storng cards come

            averageNumberOfTokens += difficulty <= 2 ? 1 : 2

            nbrOfTokens = 0
            var n = 0

            // For each card in safe zone if too strong, switch it with a random not strong card from the non safe zone
            do {
                nbrOfTokens = 0
                tmpDeck = shuffle(tmpDeck)
                for (var i = 0; i < safeZoneSize; i++) {
                    if (isThisCardTooStrong(tmpDeck[i], cardsTooStrong_tmp)) {
                        var cardIdToSwitchWith = 0
                        do {
                            cardIdToSwitchWith = Math.floor(Math.random() * (tmpDeck.length - safeZoneSize) + safeZoneSize)
                            let cardTmp = tmpDeck[i]
                            tmpDeck[i] = tmpDeck[cardIdToSwitchWith]
                            tmpDeck[cardIdToSwitchWith] = cardTmp
                        } while (isThisCardTooStrong(tmpDeck[i], cardsTooStrong_tmp))
                    }

                    if (tmpDeck[i].type == "token") {
                        nbrOfTokens += 1
                    }
                }
                console.log("loop " + n + " + in " + safeZoneSize + " max " + averageNumberOfTokens + " has " + nbrOfTokens + " tokens ")
                n += 1
            } while (nbrOfTokens >= averageNumberOfTokens && n < 100)

            // Failed to shuffle without strong cards
            if (n >= 50) {
                deck_tmp = shuffle(deck_tmp)
            } else {
                // Once safeZone has the good amount of cards, space them to make interesting rounds

                const maxNumberOfTokensInARow = Math.ceil(safeZoneSize / averageNumberOfTokens) + 1
                let currentNumberofTokensInARow = 0
                let previousCardWasAlreadyNonToken = true

                for (var i = 0; i < safeZoneSize; i++) {
                    // Check if two non token in a row or we reached too much token in a row
                    const card = tmpDeck[i]
                    if (card.type == "token") {
                        currentNumberofTokensInARow += 1
                        if (currentNumberofTokensInARow > maxNumberOfTokensInARow) {
                            currentNumberofTokensInARow = 0
                            // Switch with first non token
                            for (var j = i + 1; j < safeZoneSize && tmpDeck[i].type == "token"; j++) {
                                if (tmpDeck[j].type != "token") {
                                    const tmp = tmpDeck[i]
                                    tmpDeck[i] = tmpDeck[j]
                                    tmpDeck[j] = tmp
                                }
                            }
                        }
                    } else {
                        if (previousCardWasAlreadyNonToken) {
                            previousCardWasAlreadyNonToken = false
                            // Switch with first token
                            for (var j = i + 1; j < safeZoneSize && tmpDeck[i].type != "token"; j++) {
                                if (tmpDeck[j].type == "token") {
                                    const tmp = tmpDeck[i]
                                    tmpDeck[i] = tmpDeck[j]
                                    tmpDeck[j] = tmp
                                }
                            }
                        } else {
                            previousCardWasAlreadyNonToken = true
                        }
                    }

                }
                deck_tmp = tmpDeck
            }
        } else {
            deck_tmp = shuffle(deck_tmp)
        }

        // Apply foil effects
        deck_tmp.forEach((card) => {
            if (options.foilEffect[1] && card.type != "token") {
                card.cardCapacities.isFoil = true
            }
            if (options.foilEffect[2] && card.type == "token") {
                card.cardCapacities.isFoil = true
            }
            if (options.foilEffect[0] && cardsTooStrong_tmp.some(e => e.id === card.id)) {
                card.cardCapacities.isFoil = true
            }
        })

        tokens_tmp.forEach((card) => {
            if (options.foilEffect[1] && card.type != "token") {
                card.cardCapacities.isFoil = true
            }
            if (options.foilEffect[2] && card.type == "token") {
                card.cardCapacities.isFoil = true
            }
            if (options.foilEffect[0] && cardsTooStrong_tmp.some(e => e.id === card.id)) {
                card.cardCapacities.isFoil = true
            }
        })

        weakCards_tmp.forEach((card) => {
            if (options.foilEffect[1] && card.type != "token") {
                card.cardCapacities.isFoil = true
            }
            if (options.foilEffect[2] && card.type == "token") {
                card.cardCapacities.isFoil = true
            }
            if (options.foilEffect[0] && cardsTooStrong_tmp.some(e => e.id === card.id)) {
                card.cardCapacities.isFoil = true
            }
        })

        if (options.foilEffect[0]) {
            strongCards_tmp.forEach((card) => {
                card.cardCapacities.isFoil = true
            })
        }

        // Spawn one randomly or all premanents from the start section
        if (weakCards_tmp.length > 0) {
            if (options.startWithPermanent) {
                let card = weakCards_tmp[Math.floor(Math.random() * weakCards_tmp.length)]
                addCardToBoard(card)
            } else {
                weakCards_tmp.forEach(card => {
                    addCardToBoard(card)
                })
            }
            regroupSameCardOnBoard()
        }

        if (options.alternateMilestones && options.alternateMilestones_shuffle) {
            strongCards_tmp = shuffle(strongCards_tmp)
        }

        setTokens(tokens_tmp)
        setCardsTooStrong(cardsTooStrong_tmp)
        setWeakCards(weakCards_tmp)
        setStrongCards(strongCards_tmp)
        setDeck(deck_tmp)
        setDeckSizeAtStart(deck_tmp.length)
        saveGameState(0)

        if (props.cloudFunctions) {
            props.cloudFunctions.iniGroup_setup(options, selectedDeck, tokens_tmp, deck_tmp.length)
        }
    }

    function iniDeckList() {
        // For multiplayer read only game
        if (selectedDeck == false) {
            setGameStarted(true)
            return
        } else if (selectedDeck.isOffline) {
            fetch(require('./../../assets/DeckLists/' + selectedDeck.deckList))
                .then(r => r.text())
                .then(text => {
                    iniDeckListWithText(text)
                })
        } else {
            iniDeckListWithText(selectedDeck.deckList)
        }
    }

    function isThisCardTooStrong(card, cardsTooPowerful) {
        for (var i = 0; i < cardsTooPowerful.length; i++) {
            if (cardsTooPowerful[i].id == card.id) {
                return true
            }
        }
        return false
    }

    function newCard(count, id, type, cardCapacities, counters, name, showFront = true) {
        console.log(name)
        return {
            count: parseInt(count),
            id: id,
            type: type,
            cardCapacities: cardCapacities,
            counters: counters,
            key: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
            cardName: name,
            showFront: showFront
        }
    }

    function recreateCard(card) {
        return newCard(card.count, card.id, card.type, card.cardCapacities, card.counters, card.cardName, card.showFront)
    }

    function shuffle(a) {
        let array = a
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array
    }

    function addCardToDeck(card, oracleId, deck, tokens) {
        let tmp_deck = [...deck]
        let tmp_tokens = [...tokens]
        let tmpCard = newCard(1, card.id, card.type, card.cardCapacities, 0, card.cardName)

        let cardCount = card.count

        if (card.type == "token") {
            tmp_tokens = addTokenToTokenRow(tmpCard, oracleId, tokens)
            cardCount *= options.tokenMultiplicator
            console.log("card " + cardCount + " with " + options.tokenMultiplicator)
        }

        for (var i = 0; i < cardCount; i++) {
            tmp_deck.push(tmpCard)
        }

        return [tmp_deck, tmp_tokens]
    }

    function addTokenToTokenRow(token, oracleId, tokens) {
        let tmp = [...tokens]
        let tmpCard = newCard(1, token.id, token.type, token.cardCapacities, 0, token.cardName)
        if (!tokens.includes(token)) {
            tmp.push(token)
            // OBSOLETE
            /*
            setCardIdsToReplace(cardIdsToReplace.push({
                cardId: tmpCard.id,
                oracleId: oracleId
            }))*/
        }
        return tmp
    }

    function handleDrawPerTurn() {
        // return true if first draw of the turn
        const isFirstDraw = drawThisTurn == 0 || alternateDrawValue != -1

        if (isFirstDraw) {
            saveGameState(turnNumber)
            setTurnNumber(turnNumber + 1)
        }

        if (drawThisTurn >= drawPerTurn - 1) {
            setDrawThisTurn(0)
        } else {
            setDrawThisTurn(drawThisTurn + 1)
        }

        return isFirstDraw
    }

    function draw() {
        const isFirstDraw = handleDrawPerTurn()

        if (deck.length != 0) {
            // Draw one non token from library
            var topCard
            var cards = []
            let deckTmp = [...deck]
            do {
                topCard = deckTmp.shift();

                let card = newCard(1, topCard.id, topCard.type, topCard.cardCapacities, 0, topCard.cardName)
                cards.push(card)
                console.log("Drawing " + topCard.type + " " + topCard.id)

                spawnStrongPermanentIfNeeded()
            } while (topCard.type == "token" && deckTmp.length > 0);

            setDeck(deckTmp)
            setCardsToCast_fromLibrary(regroupSameCardOnArray(cards))
        }

        drawSuccessfull(isFirstDraw)
    }

    function alternateDraw() {
        const isFirstDraw = handleDrawPerTurn()

        if (deck.length != 0) {
            var cards = []
            let deckTmp = [...deck]
            for (var i = 0; i < alternateDrawValue && deckTmp.length > 0; i++) {
                var topCard = deckTmp.shift();
                let card = newCard(1, topCard.id, topCard.type, topCard.cardCapacities, 0, topCard.cardName)
                cards.push(card)
            }
            spawnStrongPermanentIfNeeded()
            setDeck(deckTmp)
            setCardsToCast_fromLibrary(regroupSameCardOnArray(cards))
        }

        drawSuccessfull(isFirstDraw)
    }

    function drawSuccessfull(isFirstDraw) {
        // Handle cards from graveyard with flashback
        if (isFirstDraw) {
            let tmpGraveyard = []
            let tmpArray = [...graveyard]
            for (var i = 0; i < tmpArray.length; i++) {
                let tmpCard = recreateCard(tmpArray[i])
                tmpCard.counters = 0
                if (tmpCard.cardCapacities.castFromGraveyard == true) {
                    tmpArray.splice(i, 1)
                    i -= 1
                    tmpGraveyard.push(tmpCard)
                }
            }

            setGraveyard(regroupSameCardOnArray(tmpArray))
            setCardsToCast_fromGraveyard(regroupSameCardOnArray(tmpGraveyard))

            setCardsToCast_fromHand(hand)
            setHand([])
        } else {
            setCardsToCast_fromGraveyard([])
            setCardsToCast_fromHand([])
        }

        setMillCount(0)
        setShowTopCard(false)
        setCardPressMode(0)
    }

    function addCardToBoard(card) {
        if (card.type == "creature" || card.type == "token") {
            setBoard(board.push(card))
        } else if (card.type == "enchantment" || card.type == "artifact") {
            setBoard(board.unshift(card))
        }
    }

    function playCardsRevealed() {
        if (isSpectating) { return }
        for (var i = 0; i < cardsToCast_fromLibrary.length; i++) {
            playCard(cardsToCast_fromLibrary[i])
        }
        for (var i = 0; i < cardsToCast_fromGraveyard.length; i++) {
            let thenExile = cardsToCast_fromGraveyard[i].cardCapacities.castFromGraveyardThenExile
            if (!thenExile) {
                playCard(cardsToCast_fromGraveyard[i])
            } else {
                sendToExile(cardsToCast_fromGraveyard[i])
            }
        }
        for (var i = 0; i < cardsToCast_fromHand.length; i++) {
            playCard(cardsToCast_fromHand[i])
        }
        setCardsToCast_fromLibrary([])
        setCardsToCast_fromGraveyard([])
        setCardsToCast_fromHand([])

        regroupSameCardOnBoard()
        regroupSameCardOnGraveyard()
        setShouldResetAttackersk(true)
        setAttackers([])
    }

    function playCard(card) {
        if (card.type == "instant" || card.type == "sorcery") {
            sendToGraveyard(card)
        } else {
            addCardToBoard(card)
        }
    }

    function regroupSameCardOnArray(array, reversed) {
        var tmpArray = [...array]

        if (reversed == undefined) {
            var i = 0
            while (i < tmpArray.length) {
                var j = i + 1
                while (j < tmpArray.length) {
                    if (tmpArray[i].id == tmpArray[j].id && tmpArray[j].count > 0 && tmpArray[i].counters == tmpArray[j].counters && tmpArray[i].showFront == tmpArray[j].showFront) {
                        tmpArray[i].count += tmpArray[j].count
                        tmpArray[j].count = 0
                    }

                    j += 1
                }
                i += 1
            }
        } else {
            var i = tmpArray.length - 1
            while (i >= 0) {
                var j = i - 1
                while (j >= 0) {
                    if (tmpArray[i].id == tmpArray[j].id && tmpArray[j].count > 0 && tmpArray[i].counters == tmpArray[j].counters && tmpArray[i].showFront == tmpArray[j].showFront) {
                        tmpArray[i].count += tmpArray[j].count
                        tmpArray[j].count = 0
                    }

                    j -= 1
                }
                i -= 1
            }
        }

        for (var i = 0; i < tmpArray.length; i++) {
            if (tmpArray[i].count <= 0) {
                tmpArray.splice(i, 1)
                i -= 1
            }
        }
        return tmpArray
    }

    function regroupSameCardOnBoard() {
        setBoard(regroupSameCardOnArray(board))
    }

    function regroupSameCardOnGraveyard() {
        setGraveyard(regroupSameCardOnArray(graveyard, true))
    }

    function removeOneFromBoard(index) {
        let card = newCard(1, board[index].id, board[index].type, board[index].cardCapacities, 0, board[index].cardName)

        board[index].count -= 1
        regroupSameCardOnBoard()

        removeCardFromAttackersPanel(card)
    }

    function destroyOneFromBoard(index) {
        let card = newCard(1, board[index].id, board[index].type, board[index].cardCapacities, 0, board[index].cardName)

        sendToGraveyard(card)
        board[index].count -= 1
        regroupSameCardOnBoard()
        regroupSameCardOnGraveyard()

        removeCardFromAttackersPanel(card)
    }

    function exileOneFromBoard(index) {
        let tmp = recreateCard(board[index])
        tmp.count = 1
        sendToExile(tmp)
        board[index].count -= 1
        regroupSameCardOnBoard()
    }

    function cardOnBoardPressed(index) {
        if (cardPressMode == 0) {
            destroyOneFromBoard(index)
        } else if (cardPressMode == 2) {
            returnToHand(index)
        } else {
            if (cardPressMode == 1) {
                addCounter(index, false)
            } else {
                removeCounter(index, false)
            }
        }
    }

    function returnToHand(index) {
        let tmpCard = recreateCard(board[index])
        tmpCard.count = 1
        tmpCard.counters = 0

        if (board[index].type != "token" || options.allowTokensHandAndGraveyard) {
            let handTmp = hand
            handTmp.push(tmpCard)
            setHand(handTmp)
        }

        removeOneFromBoard(index)
    }

    function boardPutToTopLibrary(index) {
        let tmpCard = recreateCard(board[index])
        tmpCard.count = 1
        tmpCard.counters = 0

        let tmpDeck = [...deck]
        tmpDeck.unshift(tmpCard)
        setDeck(tmpDeck)

        removeOneFromBoard(index)
    }

    function boardPutAtBottomLibrary(index) {
        let tmpCard = recreateCard(board[index])
        tmpCard.count = 1
        tmpCard.counters = 0

        let tmpDeck = [...deck]
        tmpDeck.push(tmpCard)
        setDeck(tmpDeck)

        removeOneFromBoard(index)
    }

    function boardShuffleIntoLibrary(index) {
        let tmpCard = recreateCard(board[index])
        tmpCard.count = 1
        tmpCard.counters = 0

        let tmpDeck = [...deck]
        tmpDeck.push(tmpCard)
        setDeck(shuffle(tmpDeck))

        removeOneFromBoard(index)
        setShowTopCard(false)
    }

    function removeCounter(index, toAllCard) {
        if (board[index].counters > 0) {
            if (toAllCard) {
                board[index].counters -= 1
            } else {
                let tmpCard = recreateCard(board[index])
                tmpCard.count -= 1
                board[index].count = 1
                board[index].counters -= 1

                board.splice(index + 1, 0, tmpCard)
            }
            regroupSameCardOnBoard()
        }
    }

    function addCounter(index, toAllCard) {
        if (toAllCard) {
            board[index].counters += 1
        } else {
            let tmpCard = recreateCard(board[index])
            tmpCard.count -= 1
            board[index].count = 1
            board[index].counters += 1

            board.splice(index + 1, 0, tmpCard)
        }
        regroupSameCardOnBoard()
    }

    function sendToGraveyard(card) {
        if (card.type != "token" || options.allowTokensHandAndGraveyard) {
            let newCard = card
            graveyard.push(recreateCard(card))
            setLastMilledToken(false)
        } else {
            setLastMilledToken(recreateCard(card))
        }
    }

    function sendToExile(card) {
        if (card.type != "token" || options.allowTokensHandAndGraveyard) {
            let tmp = recreateCard(card)
            tmp.counters = 0
            exile.push(tmp)
            setExile(regroupSameCardOnArray(exile))
        }
    }

    function millOne() {
        if (isSpectating) { return }
        if (deck.length == 0) {
            return
        }
        let deck_tmp = [...deck]
        let card = deck_tmp.shift()
        let newCard_tmp = newCard(1, card.id, card.type, card.cardCapacities, 0, card.cardName)
        setDeck(deck_tmp)
        sendToGraveyard(newCard_tmp)
        setMillCount(millCount + 1)

        spawnStrongPermanentIfNeeded()
        setShowTopCard(false)
        regroupSameCardOnGraveyard()
    }

    function toggleShowGraveyardView() {
        setShowGraveyard(!showGraveyard)
    }

    function playFromGraveyard(index) {
        let card = graveyard[index]
        if (card.type == "instant" || card.type == "sorcery") { return }
        let newCard_tmp = newCard(1, card.id, card.type, card.cardCapacities, 0, card.cardName)
        addCardToBoard(newCard_tmp)
        regroupSameCardOnBoard()

        removeCardFromGraveyard(index)
    }

    function sendCardTopLibrary(index) {
        let card = graveyard[index]

        var tmpArray = [...deck]
        tmpArray.unshift(card)
        setDeck(tmpArray)

        removeCardFromGraveyard(index)
    }

    function sendCardBottomLibrary(index) {
        let card = graveyard[index]

        var tmpArray = [...deck]
        tmpArray.push(card)
        setDeck(tmpArray)

        removeCardFromGraveyard(index)
    }

    function shuffleCardIntoLibrary(index) {
        var tmpArray = [...deck]
        tmpArray.unshift(graveyard[index])
        setDeck(shuffle(tmpArray))

        removeCardFromGraveyard(index)
    }

    function exileCardFromGraveyard(index) {
        let tmp = recreateCard(graveyard[index])
        tmp.count = 1
        removeCardFromGraveyard(index)
        sendToExile(tmp)
    }

    function removeCardFromGraveyard(index) {
        var tmpArray = [...graveyard]
        if (tmpArray[index].count > 1) {
            tmpArray[index].count -= 1
        } else {
            tmpArray.splice(index, 1)
        }
        setGraveyard(regroupSameCardOnArray(tmpArray))
    }

    function removeCardFromExile(index) {
        var tmpArray = [...exile]
        if (tmpArray[index].count > 1) {
            tmpArray[index].count -= 1
        } else {
            tmpArray.splice(index, 1)
        }
        setExile(regroupSameCardOnArray(tmpArray))
    }

    function playFromExile(index) {
        let card = exile[index]
        let newCard_tmp = newCard(1, card.id, card.type, card.cardCapacities, 0, card.cardName)
        playCard(newCard_tmp)
        regroupSameCardOnBoard()
        regroupSameCardOnGraveyard()

        removeCardFromExile(index)
    }

    function graveyardFromExile(index) {
        let card = exile[index]
        let newCard_tmp = newCard(1, card.id, card.type, card.cardCapacities, 0, card.cardName)
        sendToGraveyard(newCard_tmp)
        regroupSameCardOnGraveyard()

        removeCardFromExile(index)
    }

    function spawnOneToken(card) {
        if (isSpectating) { return }
        let token = newCard(1, card.id, card.type, card.cardCapacities, 0, card.cardName)
        playCard(token)
        regroupSameCardOnBoard()
        regroupSameCardOnGraveyard()
    }

    function boardWipe() {
        for (var i = 0; i < board.length; i++) {
            let card = board[i]
            let tmpCard = newCard(card.count, card.id, card.type, card.cardCapacities, card.counters, card.cardName)
            if (tmpCard.type == "token" || tmpCard.type == "creature") {
                for (var j = 0; j < tmpCard.count; j++) {
                    destroyOneFromBoard(i)
                }
            }
        }
        setAttackers([])
        setShowBoardWipeConfirm(false)
    }

    function drawOne() {
        if (deck.length == 0) {
            return
        }
        let deck_tmp = [...deck]
        let card = deck_tmp.shift()
        let newCard_tmp = newCard(1, card.id, card.type, card.cardCapacities, 0, card.cardName)
        setDeck(deck_tmp)

        let handTmp = [...hand]
        handTmp.push(newCard_tmp)
        setHand(handTmp)

        spawnStrongPermanentIfNeeded()
        setShowTopCard(false)
    }

    function topShuffle() {
        setDeck(shuffle([...deck]))
        setShowTopCard(false)
    }

    function topToBottom() {
        var tmpArray = [...deck]
        tmpArray.push(deck[0])
        tmpArray.shift()
        setDeck(tmpArray)

        setShowTopCard(false)
    }

    function topCast() {
        var tmpArray = [...deck]
        let card = tmpArray.shift()
        let newCard_tmp = newCard(1, card.id, card.type, card.cardCapacities, 0, card.cardName)
        playCard(newCard_tmp)
        regroupSameCardOnBoard()
        regroupSameCardOnGraveyard()

        setDeck(tmpArray)
        setShowTopCard(false)
    }

    function toggleRevealTop() {
        setShowTopCard(!showTopCard)
    }

    function discardOne() {
        let tmpArray = [...hand]
        let card = tmpArray.splice(Math.floor(Math.random() * tmpArray.length), 1)[0]
        sendToGraveyard(recreateCard(card))
        setHand(tmpArray)
        setMillCount(0)
        regroupSameCardOnGraveyard()
    }

    function spawnStrongPermanentIfNeeded() {
        if (strongCards.length == 0 || options.alternateMilestones) {
            return
        }

        const getStrongPermanent = () => {
            let mileStonesCount = 0 + (milestones[0] ? 1 : 0) + (milestones[1] ? 1 : 0) + (milestones[2] ? 1 : 0) + (milestones[3] ? 1 : 0)
            if (mileStonesCount <= strongCards.length) {
                let strongCards_tmp = [...strongCards]
                let card = strongCards_tmp.splice(Math.floor(Math.random() * strongCards_tmp.length), 1)[0]
                setStrongCards(strongCards_tmp)
                return card
            } else {
                return strongCards[Math.floor(Math.random() * strongCards.length)]
            }
        }

        let milestonesToCast = []
        if (milestones[0] && deck.length <= deckSizeAtStart - deckSizeAtStart / 4) {
            let card = getStrongPermanent()
            if (card != undefined) {
                milestonesToCast.push(recreateCard(card))
            }

            let milestones_tmp = milestones
            milestones_tmp[0] = false
            setMilestones(milestones_tmp)
        }
        if (milestones[1] && deck.length <= deckSizeAtStart / 2) {
            let card = getStrongPermanent()
            if (card != undefined) {
                milestonesToCast.push(recreateCard(card))
            }

            let milestones_tmp = milestones
            milestones_tmp[1] = false
            setMilestones(milestones_tmp)
        }
        if (milestones[2] && deck.length <= deckSizeAtStart / 4) {
            let card = getStrongPermanent()
            if (card != undefined) {
                milestonesToCast.push(recreateCard(card))
            }

            let milestones_tmp = milestones
            milestones_tmp[2] = false
            setMilestones(milestones_tmp)
        }
        if (milestones[3] && deck.length <= 0) {
            let card = getStrongPermanent()
            if (card != undefined) {
                milestonesToCast.push(recreateCard(card))
            }

            let milestones_tmp = milestones
            milestones_tmp[3] = false
            setMilestones(milestones_tmp)
        }



        for (var i = 0; i < milestonesToCast.length; i++) {
            playCard(recreateCard(milestonesToCast[i]))
        }
        if (milestonesToCast.length > 0) {
            setCardsToCast_milestone(milestonesToCast)
            regroupSameCardOnBoard()
            regroupSameCardOnGraveyard()
        }
    }

    function removeCardFromAttackersPanel(card) {
        // Also remove from attackers panel
        let tmpArray = [...attackers]
        let i = tmpArray.findIndex((c) => { return card.id == c.id })
        if (i >= 0) {
            tmpArray[i].count -= 1
            if (tmpArray[i].count == 0) {
                tmpArray.splice(i, 1)
            }
            if (tmpArray.length == 0) {
                setShowAttackers(false)
            }
            setAttackers(tmpArray)
        }
    }

    function graveyard_exileAll() {
        graveyard.forEach((card) => {
            sendToExile(card)
        })
        setGraveyard([])
        setShowGraveyard(false)
    }

    function graveyard_allBattlefield() {
        let graveyardTmp = []
        graveyard.forEach((card) => {
            if (card.type != "instant" && card.type != "sorcery") {
                let cardTmp = recreateCard(card)
                cardTmp.count = 1
                for (let n = 0; n < card.count; n++) {
                    playCard(recreateCard(cardTmp))
                }
            } else {
                graveyardTmp.push(recreateCard(card))
            }
        })
        regroupSameCardOnBoard()
        setGraveyard(graveyardTmp)
        setShowGraveyard(false)
    }

    function graveyard_shuffleAll() {
        let deckTmp = [...deck]
        graveyard.forEach((card) => {
            let cardTmp = recreateCard(card)
            cardTmp.count = 1
            for (let n = 0; n < card.count; n++) {
                deckTmp.push(recreateCard(cardTmp))
            }
        })
        deckTmp = shuffle(deckTmp)
        setDeck(deckTmp)
        setShowTopCard(false)
        graveyard_exileAll()
    }

    function graveyard_allTop() {
        let graveyardTmp = []
        graveyard.forEach((card) => {
            let cardTmp = recreateCard(card)
            cardTmp.count = 1
            for (let n = 0; n < card.count; n++) {
                graveyardTmp.push(recreateCard(cardTmp))
            }
        })
        graveyardTmp = shuffle(graveyardTmp)
        let deckTmp = [...deck]
        graveyardTmp.forEach((card) => {
            deckTmp.unshift(card)
        })
        setDeck(deckTmp)
        setShowTopCard(false)
        graveyard_exileAll()
    }

    function graveyard_allBottom() {
        let graveyardTmp = []
        graveyard.forEach((card) => {
            let cardTmp = recreateCard(card)
            cardTmp.count = 1
            for (let n = 0; n < card.count; n++) {
                graveyardTmp.push(recreateCard(cardTmp))
            }
        })
        graveyardTmp = shuffle(graveyardTmp)
        let deckTmp = [...deck]
        graveyardTmp.forEach((card) => {
            deckTmp.push(card)
        })
        setDeck(deckTmp)
        graveyard_exileAll()
    }

    function flipOneCard(card, index) {
        console.log("Flip one")
        let cardTmp = recreateCard(card)
        cardTmp.showFront = !card.showFront
        cardTmp.count = 1

        //addCardToBoard(cardTmp)

        if (card.type == "creature" || card.type == "token") {
            board.splice(index + 1, 0, cardTmp)
        } else if (card.type == "enchantment" || card.type == "artifact") {
            board.splice(index + 1, 0, cardTmp)
        }

        exileOneFromBoard(index)
        regroupSameCardOnBoard()
    }

    function flipAllCard(index) {
        console.log("Flip all")
        board[index].showFront = !board[index].showFront
        regroupSameCardOnBoard()
    }


    //----------------------- TIME TRAVEL -----------------------

    function moveToPreviousTurn() {
        if (turnNumber <= 0) { return }
        if (turnNumber == gameStatesArray.length) {
            saveGameState(turnNumber)
        }
        restoreGameState(turnNumber - 1)
        setTurnNumber(turnNumber - 1)
    }

    function moveToNextTurn() {
        if (turnNumber >= gameStatesArray.length) { return }
        if (turnNumber + 2 == gameStatesArray.length) {
            let tmp = [...gameStatesArray]
            tmp.length = tmp.length - 1
            setGameStatesArray(tmp)
        }
        restoreGameState(turnNumber + 1)
        setTurnNumber(turnNumber + 1)
    }

    function saveGameState(turnToSave) {
        if (turnToSave < 0) { return }
        let newArray = [...gameStatesArray]
        if (turnToSave < newArray.length) {
            newArray[turnNumber] = getGameStateJSON()
            newArray.length = turnNumber + 1
            setGameStatesArray(newArray)
            // delete after this index
        } else if (turnToSave == newArray.length) {
            newArray.push(getGameStateJSON())
            setGameStatesArray(newArray)
        } else {
            // saving 2 or more turn ahead
            console.log("Im stupid")
        }
        console.log("Turn " + turnToSave + " saved")
        console.log(gameStatesArray)
    }

    function getGameStateJSON() {
        let boardTmp = []
        board.forEach((card) => boardTmp.push(recreateCard(card)))
        let graveyardTmp = []
        graveyard.forEach((card) => graveyardTmp.push(recreateCard(card)))
        let exileTmp = []
        exile.forEach((card) => exileTmp.push(recreateCard(card)))
        let attackersTmp = []
        attackers.forEach((card) => attackersTmp.push(recreateCard(card)))

        return {
            board: boardTmp,
            deck: [...deck],
            lifepoints: lifepoints,
            graveyard: graveyardTmp,
            hand: [...hand],
            attackers: attackersTmp,
            shouldResetAttackers: shouldResetAttackers,
            exile: exileTmp,
            drawPerTurn: drawPerTurn,
            drawThisTurn: drawThisTurn,
            strongCards: [...strongCards]
        }
    }

    function restoreGameState(turnNumberToRestore) {
        if (turnNumberToRestore < 0 || turnNumberToRestore > gameStatesArray.length) { return }
        let gameState = gameStatesArray[turnNumberToRestore]
        setBoard(regroupSameCardOnArray(gameState.board))
        setDeck(gameState.deck)
        setLifepoints(gameState.lifepoints)

        setGraveyard(regroupSameCardOnArray(gameState.graveyard, true))
        setHand(gameState.hand)
        setAttackers(gameState.attackers)
        setShouldResetAttackersk(gameState.shouldResetAttackers)
        setExile(regroupSameCardOnArray(gameState.exile))
        setStrongCards(gameState.strongCards)

        setDrawPerTurn(gameState.drawPerTurn)
        setDrawThisTurn(gameState.drawThisTurn)

        setMillCount(0)
        setLastMilledToken(false)
        setShowTopCard(false)
        setCardPressMode(0)
        setShowBoardWipeConfirm(false)
    }


    if (gameStarted) {
        return (
            <div id="GameReborn"
                class={options.backgroundImageUrl != undefined && options.backgroundImageUrl.length > 0 ? " withBackgroundImage" : ("background-gradient-" + options.backgroundColor)}
                style={options.backgroundImageUrl != undefined && options.backgroundImageUrl.length > 0 ? { backgroundImage: `url(${options.backgroundImageUrl})` } : {}}
            >
                <div class="text-white">
                    <div class="game-main noScrollbar d-flex flex-column justify-content-between">
                        <div class="board">
                            <p class="board-row-title">Creatures ({board.filter((card) => card.type == "creature" || card.type == "token").reduce((total, card) => total + card.count, 0)})</p>
                            <CustomScrollContainer>
                                <div>
                                    {board.map(function (card, i) {
                                        if (card.type != "enchantment" && card.type != "artifact") {
                                            return (
                                                <Card_Board card={card}
                                                    cardClicked={cardOnBoardPressed}
                                                    index={i} key={"Board_" + card.key}
                                                    cardIdsToReplace={cardIdsToReplace}
                                                    flipOnlyOne={card.count > 1 ? (() => { flipOneCard(card, i) }) : (undefined)}
                                                    flipAll={() => flipAllCard(i)}
                                                    exileOneFromBoard={exileOneFromBoard}
                                                    isSpectating={isSpectating}
                                                    cardCount={card.count}
                                                    cardCounters={card.counter}
                                                    forcedShowBack={!card.showFront}
                                                    returnToHand={() => returnToHand(i)}
                                                    addCounter={() => addCounter(i, false)}
                                                    removeCounter={() => removeCounter(i, false)}
                                                    addCounterAll={() => addCounter(i, true)}
                                                    removeCounterAll={() => removeCounter(i, true)}
                                                    top={() => boardPutToTopLibrary(i)}
                                                    bottom={() => boardPutAtBottomLibrary(i)}
                                                    shuffle={() => boardShuffleIntoLibrary(i)}
                                                />
                                            )
                                        }
                                        return
                                    }, this)}
                                    <div class="margin"></div>
                                </div>
                            </CustomScrollContainer>
                        </div>
                        <div class="bottom-part d-flex flex-row justify-content-between">
                            <div class="board">
                                <p class="board-row-title">Non creatures ({board.filter((card) => card.type != "creature" && card.type != "token").reduce((total, card) => total + card.count, 0)})</p>
                                <CustomScrollContainer>
                                    <div>
                                        {board.map(function (card, i) {
                                            if (card.type == "enchantment" || card.type == "artifact") {
                                                return (
                                                    <Card_Board card={card}
                                                        cardClicked={cardOnBoardPressed}
                                                        index={i} key={"Board_" + card.key}
                                                        cardIdsToReplace={cardIdsToReplace}
                                                        flipOnlyOne={card.count > 1 ? (() => { flipOneCard(card, i) }) : (undefined)}
                                                        flipAll={() => flipAllCard(i)} exileOneFromBoard={exileOneFromBoard}
                                                        isSpectating={isSpectating}
                                                        cardCount={card.count}
                                                        cardCounters={card.counter}
                                                        forcedShowBack={!card.showFront}
                                                        returnToHand={() => returnToHand(i)}
                                                        addCounter={() => addCounter(i, false)}
                                                        removeCounter={() => removeCounter(i, false)}
                                                        addCounterAll={() => addCounter(i, true)}
                                                        removeCounterAll={() => removeCounter(i, true)}
                                                        top={() => boardPutToTopLibrary(i)}
                                                        bottom={() => boardPutAtBottomLibrary(i)}
                                                        shuffle={() => boardShuffleIntoLibrary(i)}
                                                    />
                                                )
                                            }
                                            return
                                        }, this)}
                                        <div class="margin"></div>
                                    </div>
                                </CustomScrollContainer>
                            </div>
                            <div class="graveyard-and-library d-flex flex-row">
                                <div class="library cursor-pointer">
                                    <div class={isSpectating ? "interaction-disabled" : ""}>
                                        <MilledCardBubble millCount={millCount} clicked={() => { setMillCount(0) }} />
                                    </div>
                                    {deck.length > 0 ? (<div class="card-pile shadowed"></div>) : (<div></div>)}
                                    {deck.length > 0 ? (
                                        showTopCard ? (
                                            <div class="position-absolute deck-wrapper" style={{ top: 0 }}>
                                                <img src={require('./../../assets/MTG-background.jpg')} class="overflow-hidden card" style={{ top: -4 * deck.length / deckSizeAtStart + 'dvh' }} onClick={millOne} />
                                                <div class="position-absolute card-action-info revealed-card" style={{ top: -4 * deck.length / deckSizeAtStart + 'dvh' }}>
                                                    <CardIMG card={deck[0]} classes={"card"} isFoil={deck[0].cardCapacities.isFoil} onClick={millOne} isSpectating={isSpectating} />
                                                    <p class="action-info">Mill</p>
                                                    <p class="cardCount">{deck.length}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div class="card-action-info overflow-hidden card position-absolute" style={{ top: -4 * deck.length / deckSizeAtStart + 'dvh' }}>
                                                <img src={require('./../../assets/MTG-background.jpg')} onClick={millOne} />
                                                <p class="action-info">Mill</p>
                                                <p class="cardCount">{deck.length}</p>
                                            </div>
                                        )

                                    ) : (
                                        <div class="card blurred-background shadowed"></div>
                                    )}
                                    <LibraryTopCardManager showTopCard={showTopCard} reveal={toggleRevealTop} bottom={topToBottom} shuffle={topShuffle} cast={topCast} library={deck} isSpectating={isSpectating} />
                                </div>
                                <div class="graveyard cursor-pointer position-relative no-mdfc" onClick={toggleShowGraveyardView} >
                                    {graveyard.length > 0 ? (
                                        <div class="card-action-info card shadowed">
                                        <CardIMG card={graveyard[graveyard.length - 1]} classes={""} isFoil={graveyard[graveyard.length - 1].cardCapacities.isFoil} />
                                        <p class="action-info">Show</p>
                                        </div>
                                    ) : (
                                        <div class="card shadowed blurred-background"></div>
                                    )}

                                    {
                                        lastMilledToken ? (<div class="tokenExiled-wrapper"><CardIMG card={lastMilledToken} classes={"card tokenBeingExiled"} isFoil={lastMilledToken.isFoil} key={lastMilledToken.key} /></div>) : (<div></div>)
                                    }
                                    <div class="type-count d-flex flex-column">
                                        <div class="d-flex flex-row blurred-background align-items-center">
                                            <img src={require("../../assets/Icons/minotaur.png")} />
                                            <h2>{graveyard.filter((card) => card.type == "creature" || card.type == "token").reduce((total, card) => total + card.count, 0)}</h2>
                                            <p class="">Creatures</p>
                                        </div>
                                        <div class="d-flex flex-row blurred-background align-items-center">
                                            <img src={require("../../assets/Icons/storytelling.png")} />
                                            <h2>{graveyard.filter((card) => card.type == "instant" || card.type == "sorcery").reduce((total, card) => total + card.count, 0)}</h2>
                                            <p class="">Instant and sorceries</p>
                                        </div>
                                    </div>
                                    <p class="cardCount">{graveyard.reduce((total, card) => total + card.count, 0)}</p>
                                </div>
                                <div class={(showAttackers ? "zoomed" : "") + (isSpectating ? " interaction-disabled" : "")}>
                                    <LifeCounter options={props.options} setLifepoints={(life) => setLifepoints(life)} lifepoints={lifepoints} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------------------- TOP BAR ----------------------------------------*/}

                    <div class="side-bar d-flex flex-row align-items-center">
                        <button onClick={() => {
                            if (isSpectating) {
                                window.location.href = '/'
                            } else {
                                props.setIsPlaying(false)
                            }
                        }}>
                            <img src={require("../../assets/Icons/x.png")} />
                            <p class="">Return to menu</p>
                        </button>
                        <button onClick={() => { setShowHelp(true) }}>
                            <img src={require("../../assets/Icons/question.png")} />
                            <p class="">Help</p>
                        </button>
                        <div class={"d-flex flex-row interaction-row align-items-center " + (isSpectating ? "hidden" : "")}>
                            <div class="divider"></div>
                            {
                                gameStatesArray.length > 0 && turnNumber > 0 ? (
                                    <button onClick={() => moveToPreviousTurn()}>
                                        <img src={require("../../assets/Icons/annuler.png")} />
                                        <p class="">Previous turn</p>
                                    </button>
                                ) : (<button></button>)
                            }
                            {
                                gameStatesArray.length > turnNumber + 1 ? (
                                    <button onClick={() => moveToNextTurn()}>
                                        <img src={require("../../assets/Icons/refaire.png")} />
                                        <p class="">Next turn</p>
                                    </button>
                                ) : (<button></button>)
                            }
                            <div class="divider"></div>
                            <button onClick={() => { setCardPressMode(cardPressMode == 2 ? 0 : 2) }} class={cardPressMode == 2 ? "enabled" : ""}>
                                <img src={require("../../assets/Icons/card-return.png")} />
                                <p class="">Return to hand</p>
                            </button>
                            <button onClick={drawOne}>
                                <img src={require("../../assets/Icons/card-draw.png")} />
                                <p class="">Draw one</p>
                            </button>
                            {hand.length > 0 ?
                                (<div>
                                    <Button text="Play hand" clicked={() => {
                                        setCardsToCast_fromHand(regroupSameCardOnArray(hand))
                                        setHand([])
                                    }} />
                                </div>) : (<div></div>)
                            }

                            <div class="divider"></div>
                            <div class="countersMode d-flex flex-row">
                                <button onClick={() => { setCardPressMode(cardPressMode == 1 ? 0 : 1) }} class={cardPressMode == 1 ? "enabled" : ""} >
                                    <img src={require("../../assets/Icons/plus.png")} />
                                    <p class="">Add counters</p>
                                </button>
                                <button onClick={() => { setCardPressMode(cardPressMode == -1 ? 0 : -1) }} class={cardPressMode == -1 ? "enabled" : ""}>
                                    <img src={require("../../assets/Icons/moins.png")} />
                                    <p class="">Remove counters</p>
                                </button>
                            </div>
                            <div class="divider"></div>
                            {
                                !showBoardWipeConfirm ? (
                                    <button onClick={() => setShowBoardWipeConfirm(true)}>
                                        <img src={require("../../assets/Icons/destroyed-planet.png")} />
                                        <p class="">Destroy all creatures</p>
                                    </button>) : (
                                    <div class="d-flex flex-row board-wipe-confirm">
                                        <button onClick={() => setShowBoardWipeConfirm(false)}>
                                            <img src={require("../../assets/Icons/x.png")} />
                                        </button>
                                        <button onClick={boardWipe}>
                                            <img src={require("../../assets/Icons/verifier.png")} />
                                        </button>
                                    </div>
                                )
                            }

                            <div class="divider"></div>
                            <button onClick={() => setShowExile(true)}>
                                <img src={require("../../assets/Icons/cyclone.png")} />
                                <p class="">Show exile</p>
                            </button>

                            {options.alternateMilestones ? (
                                <div>
                                    <Button text={"Milestones (" + strongCards.length + ")"} clicked={() => {
                                        if (strongCards.length <= 0) { return }
                                        let tmpStrongCards = [...strongCards]
                                        let card = tmpStrongCards.shift()

                                        let tmpMilestones = [...cardsToCast_milestone]
                                        tmpMilestones.push(card)

                                        setCardsToCast_milestone(tmpMilestones)
                                        setStrongCards(tmpStrongCards)
                                        playCard(recreateCard(card))
                                        regroupSameCardOnBoard()
                                    }} />
                                </div>
                            ) :
                                (<div></div>)
                            }
                        </div>
                        <div class="divider"></div>
                        <div class="no-mdfc token-row">
                            <CustomScrollContainer scrollSpeed={0.5}>
                                <div class="d-flex flex-row">
                                    {tokens.map(function (card, i) {
                                        return (
                                            <Card_Tokens card={card} cardClicked={spawnOneToken} key={"Token_" + card.key} />
                                        )
                                    }, this)}
                                    <Card_Tokens card={newCard(1, "43ec2f4d-d988-49a8-b2ac-598703fe8b26", "token",
                                        {
                                            castFromGraveyard: false,
                                            castFromGraveyardThenExile: false,
                                            cantAttackOrBlock: false,
                                            isFoil: false
                                        }, 0, "Copy")} cardClicked={spawnOneToken} key={"Token_Copy"} />
                                </div>
                            </CustomScrollContainer>
                        </div>
                        <div class="turn-buttons d-flex flex-row align-items-center">
                            <p class="px-3">Turn {turnNumber >= gameStatesArray.length - 1 ? turnNumber : (turnNumber + "/" + (gameStatesArray.length - 1))}</p>
                            <div class={isSpectating ? "hidden" : ""}>
                                {
                                    alternateDrawValue == -1 ? (
                                        <div class="d-flex flex-column">
                                            <div class="draw-per-turn-indicators">
                                                <div class="d-flex flex-row">
                                                    <div class="draw-per-turn-indicator" onClick={() => setDrawPerTurn(1)}></div>
                                                    <div class={"draw-per-turn-indicator" + (drawPerTurn >= 2 ? "" : " nope")} onClick={() => setDrawPerTurn(2)}></div>
                                                    <div class={"draw-per-turn-indicator" + (drawPerTurn >= 3 ? "" : " nope")} onClick={() => setDrawPerTurn(3)}></div>
                                                </div>
                                                <p>Change draw per turn</p>
                                            </div>
                                            <Button text={drawThisTurn == 0 ? "New turn" : "Draw again"} clicked={draw} classes=" primary draw-button" />
                                        </div>
                                    ) : (
                                        <div class="d-flex flex-row align-items-center">
                                            <span class="alternate-draw-buttons d-flex flex-row">
                                                <Button text="-" classes=" m-0 mx-2" clicked={() => {
                                                    if (alternateDrawValue > 1) {
                                                        setAlternateDrawValue(alternateDrawValue - 1)
                                                    }
                                                }} />
                                                <Button text="+" classes=" m-0 me-2" clicked={() => {
                                                    setAlternateDrawValue(alternateDrawValue + 1)
                                                }} />
                                            </span>

                                            <span onWheel={(e) => {
                                                if (e.deltaY > 0) {
                                                    if (alternateDrawValue > 1) {
                                                        setAlternateDrawValue(alternateDrawValue - 1)
                                                    }
                                                } else if (e.deltaY < 0) {
                                                    setAlternateDrawValue(alternateDrawValue + 1)
                                                }
                                            }}>
                                                <Button text={"Draw " + alternateDrawValue + " cards"} clicked={alternateDraw} classes=" primary" />
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                            <div>
                                <Button text="See Attackers" clicked={() => {
                                    if (shouldResetAttackers) {
                                        let attackersTmp = []
                                        board.forEach((card) => {
                                            if ((card.type == "creature" || card.type == "token") && card.cardCapacities.cantAttackOrBlock == false) {
                                                attackersTmp.push(recreateCard(card))
                                            }
                                        })
                                        setAttackers(attackersTmp)
                                        setShouldResetAttackersk(false)
                                    }
                                    setShowAttackers(true)
                                }} />
                            </div>
                        </div>
                    </div>

                    {/*---------------------------------------- TOP BAR END ----------------------------------------*/}
                </div>
                {selectedDeck.specialRules ? (
                    <h1 class="deck-special-rules">?
                        <div class="shadowed">
                            <h2>Special rules</h2>
                            <p class="mb-0">{selectedDeck.specialRules}</p>
                        </div>
                    </h1>
                ) : (<div></div>)}
                <HandView hand={hand} clicked={discardOne} />
                <GraveyardView graveyard={graveyard} showGraveyard={showGraveyard} toggleGraveyard={toggleShowGraveyardView} cardFunctions={{ play: playFromGraveyard, top: sendCardTopLibrary, bottom: sendCardBottomLibrary, shuffle: shuffleCardIntoLibrary, exile: exileCardFromGraveyard }}
                    exileAll={graveyard_exileAll} allBattlefield={graveyard_allBattlefield} shuffleAll={graveyard_shuffleAll} allTop={graveyard_allTop} allBottom={graveyard_allBottom} isSpectating={isSpectating}
                />
                <Milestones cardsToCast_milestone={cardsToCast_milestone} onClick={() => { setCardsToCast_milestone([]) }} />
                <CardsToCast cardsToCast_fromLibrary={cardsToCast_fromLibrary} cardsToCast_fromGraveyard={cardsToCast_fromGraveyard} cardsToCast_fromHand={cardsToCast_fromHand} onClick={playCardsRevealed} />
                <HelpPanel onClick={() => { setShowHelp(false) }} showHelp={showHelp} />
                <AlternativeTokensInitializer neededAlternativeTokens={cardIdsToReplace} />
                <BlockerHandler attackers={attackers} showPanel={showAttackers} hidePanel={() => { setShowAttackers(false) }}
                    updateAttackersArray={(array) => { setAttackers(array) }}
                    destroyed={(card) => {
                        let index = board.findIndex((c) => { return card.id == c.id })
                        if (index != -1) {
                            destroyOneFromBoard(index)
                        }
                    }}
                    isSpectating={isSpectating}
                />
                <ExilePanel exile={exile}
                    showPanel={showExile}
                    hidePanel={() => { setShowExile(false) }}
                    cardFunctions={{ play: playFromExile, graveyard: graveyardFromExile }}
                />
            </div>
        )
    } else {
        return (
            <div>
                <button onClick={props.iniGame}>Start</button>
            </div>
        )
    }
}

function CardsToCast(props) {
    const cardsToCast_fromLibrary = props.cardsToCast_fromLibrary
    const cardsToCast_fromGraveyard = props.cardsToCast_fromGraveyard
    const cardsToCast_fromHand = props.cardsToCast_fromHand
    const onClick = props.onClick

    function SelectDeck() {
        console.log("Selecting deck ")
    }

    if (cardsToCast_fromLibrary.length > 0 || cardsToCast_fromHand.length > 0 || cardsToCast_fromGraveyard.length > 0) {
        return (
            <FullScreenPanel
                title=""
                component={
                    <div class="cardsToCast no-mdfc">
                        <CustomScrollContainer>
                            <div>
                                {cardsToCast_fromGraveyard.length > 0 ? (
                                    <div class="cardsToCast-section">
                                        <div class="justify-content-center w-100 d-flex flex-row align-items-center">
                                            <h1>From Graveyard</h1>
                                            <h2 class="draw-card-count">({cardsToCast_fromGraveyard.reduce((total, card) => total + card.count, 0)})</h2>
                                        </div>
                                        <div class="cardsToCast-section">
                                            {cardsToCast_fromGraveyard.map(function (card, i) {
                                                return (
                                                    <Card_Cast card={card} key={"Cast_" + card.key} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : (<span />)
                                }
                                {cardsToCast_fromLibrary.length > 0 ? (
                                    <div class="cardsToCast-section">
                                        <div class="justify-content-center w-100 d-flex flex-row align-items-center">
                                            <h1>From Library</h1>
                                            <h2 class="draw-card-count">({cardsToCast_fromLibrary.reduce((total, card) => total + card.count, 0)})</h2>
                                        </div>
                                        <div class="cardsToCast-section">
                                            {cardsToCast_fromLibrary.map(function (card, i) {
                                                return (
                                                    <Card_Cast card={card} key={"Cast_" + card.key} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : (<span />)
                                }
                                {cardsToCast_fromHand.length > 0 ? (
                                    <div class="cardsToCast-section">
                                        <div class="justify-content-center w-100 d-flex flex-row align-items-center">
                                            <h1>From Hand</h1>
                                            <h2 class="draw-card-count">({cardsToCast_fromHand.reduce((total, card) => total + card.count, 0)})</h2>
                                        </div>
                                        <div class="cardsToCast-section">
                                            {cardsToCast_fromHand.map(function (card, i) {
                                                return (
                                                    <Card_Cast card={card} key={"Cast_" + card.key} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : (<span />)
                                }
                            </div>
                        </CustomScrollContainer>
                    </div>
                }
                hidePanel={onClick}
                fullScreenDismiss={true} />
        )
    }

    function Card_Cast(props) {
        const [card, setCard] = useState(props.card)

        if (props.fromGraveyard) {
            return (
                <div class="d-flex flex-column">
                    <div class="card">
                        <CardIMG card={card} isFoil={card.cardCapacities.isFoil} />
                        <p class="cardCount">{card.count > 1 ? card.count : ""}</p>
                    </div>
                </div>
            )
        }

        return (
            <div class="card">
                <CardIMG card={card} isFoil={card.cardCapacities.isFoil} />
                <p class="cardCount">{card.count > 1 ? card.count : ""}</p>
            </div>
        )
    }
}

function GraveyardView(props) {
    const graveyard = props.graveyard
    const showGraveyard = props.showGraveyard

    function hideView() {
        props.toggleGraveyard()
    }

    if (showGraveyard) {
        return (
            <FullScreenPanel
                title="Graveyard"
                component={
                    <div class="graveyardView no-mdfc">
                        <CustomScrollContainer scrollSpeed={2}>
                            <div>
                                <div class="d-flex flex-row">
                                    <div class={props.isSpectating ? "hidden" : ""}>
                                        <div class="d-flex flex-column">
                                            <Button text="Exile all" clicked={props.exileAll} />
                                            <Button text="All battlefield" clicked={props.allBattlefield} />
                                            <Button text="Shuffle all" clicked={props.shuffleAll} />
                                            <Button text="All top" clicked={props.allTop} />
                                            <Button text="All bottom" clicked={props.allBottom} />
                                        </div>
                                    </div>
                                    {graveyard.map(function (card, i) {
                                        return (
                                            <Card_Graveyard card={card} index={i} cardFunctions={props.cardFunctions} key={"Graveyard_" + card.key} isSpectating={props.isSpectating} />
                                        )
                                    })}
                                </div>
                            </div>
                        </CustomScrollContainer>
                    </ div>
                }
                hidePanel={hideView} />
        )
    }

    function Card_Graveyard(props) {
        const [card, setCard] = useState(props.card)
        const index = props.index
        /*
                function cardClicked() {
                    console.log("Clicking on " + card.id + " " + index)
        
                }
        */
        return (
            <div class="card d-flex flex-column card-action-info">
                <CardIMG card={card} onClick={() => {
                    if (props.isSpectating) { return }
                    props.cardFunctions.play(index)
                }} isFoil={card.cardCapacities.isFoil} />
                {props.card.type != "sorcery" && props.card.type != "instant" && (<p class="action-info">Cast</p>)}
                <p class="cardCount">{card.count}</p>
                <div class={props.isSpectating ? "hidden" : ""}>
                    <div>
                        <Button text="Top" clicked={() => { props.cardFunctions.top(index) }} />
                        <Button text="Bottom" clicked={() => { props.cardFunctions.bottom(index) }} />
                    </div>
                    <div>
                        <Button text="Shuffle" clicked={() => { props.cardFunctions.shuffle(index) }} />
                        <Button text="Exile" clicked={() => { props.cardFunctions.exile(index) }} />
                    </div>
                </div>
            </div>
        )
    }
}

function Card_Board(props) {
    const [card, setCard] = useState(props.card)
    const cardCount = props.card.count
    const cardCounters = props.card.counters
    const index = props.index
    const forcedShowBack = props.forcedShowBack

    function cardClicked() {
        if (props.isSpectating) { return }
        console.log("Clicking on " + card.id)
        props.cardClicked(index)
    }

    return (
        <div class="card fadeIn card-action-info">
            <CardIMG card={card} isFoil={card.cardCapacities.isFoil} onClick={cardClicked} flipOnlyOne={props.flipOnlyOne} flipAll={props.flipAll} forcedShowBack={props.isSpectating ? forcedShowBack : undefined} isSpectating={props.isSpectating} />
            {cardCounters > 0 ? (<h1 class="blurred-background shadowed fadeIn card-counters">{cardCounters}</h1>) : (<h1 />)}
            {card.cardCapacities.cantAttackOrBlock ? (<p class="cantAttackOrBlock shadowed blurred-background">Can't attack</p>) : (<div></div>)}
            {/*<div class={"card-quick-button" + (props.isSpectating ? " hidden" : "")}>
                <Button text="Exile" classes=" shadowed" clicked={() => props.exileOneFromBoard(index)}></Button>
    </div>*/}
            <p class="action-info">Destroy</p>
            <p class="cardCount">{cardCount != 1 ? cardCount : ""}</p>
            <PopUpMenu
                className={props.isSpectating ? "hidden" : ""}
                exile={() => props.exileOneFromBoard(index)}
                returnToHand={props.returnToHand}
                addCounter={props.addCounter}
                removeCounter={props.removeCounter}
                addCounterAll={props.addCounterAll}
                removeCounterAll={props.removeCounterAll}
                top={props.top}
                bottom={props.bottom}
                shuffle={props.shuffle}
            ></PopUpMenu>
        </div>
    )
}

function Card_Tokens(props) {
    const card = props.card

    function cardClicked() {
        props.cardClicked(card)
    }

    return (
        <button class="card-token-row card-action-info">
            <CardIMG card={card} isFoil={card.cardCapacities.isFoil} onClick={cardClicked} />
            <p class="action-info">+1</p>
        </button>
    )
}

function MilledCardBubble(props) {
    const millCount = props.millCount

    if (millCount > 0) {
        return (
            <div class="mill-bubble blurred-background shadowed fadeIn" onClick={props.clicked}>
                <h1 class="mt-0">Cards milled this turn : {millCount}</h1>
                <p>Click to dismiss</p>
            </div>
        )
    }
}

function HandView(props) {
    const hand = props.hand

    if (hand.length > 0) {
        return (
            <div class="hand fadeIn" onClick={props.clicked}>
                {hand.map(function (card, i) {
                    return (
                        <img src={require('./../../assets/MTG-background.jpg')} class="card-hand shadowed" key={i} />
                    )
                })}
            </div>
        )
    }
}

function LibraryTopCardManager(props) {
    if (props.isSpectating) {
        return (<div></div>)
    }
    if (props.showTopCard) {
        return (
            <div class="w-100">
                <div class="library-topCard-manager d-flex flex-column">
                    <Button text="Bottom" clicked={props.bottom} />
                    <Button text="Shuffle" clicked={props.shuffle} />
                    <Button text="Cast" clicked={props.cast} />
                </div>
            </div>
        )
    }

    if (props.library.length == 0) {
        return (<div></div>)
    }

    return (
        <div class="library-topCard-manager-reveal ">
            <Button text="Reveal top" clicked={props.reveal} />
        </div>
    )
}

function HelpPanel(props) {
    const onClick = props.onClick
    const [panel, setPanel] = useState(1);

    if (props.showHelp) {
        return (
            <FullScreenPanel
                title=""
                component={
                    <div class="d-flex flew-row" id="HelpPanel">
                        <div class="d-flex flex-column help-buttons">
                            <button onClick={() => setPanel(1)} class={panel == 1 ? "selected" : ""}>Quick start</button>
                            <button onClick={() => setPanel(2)} class={panel == 2 ? "selected" : ""}>Complete rules</button>
                            <button onClick={() => setPanel(3)} class={panel == 3 ? "selected" : ""}>FAQ</button>
                        </div>
                        <div>
                            {panel == 1 ? (<div class="help">
                                <h1>How to play</h1>
                                <h2>Setup phase</h2>
                                <p>- Survivors play 3 turns together then the Horde start playing.</p>
                                <h2>Playing a Horde turn</h2>
                                <img src={require('../../assets/img_tuto.jpg')} class="shadowed" />
                                <p>- Press the 'New turn' button until it shows 'New turn' again <strong>(1)</strong>. All cards revealed are played by the Horde.</p>
                                <p>- Apply the effects of cards played by the Horde.</p>
                                <p>- The Horde is now attacking Survivors with all its creatures (they have haste). Handle the blockers : remove lifepoints <strong>(2)</strong> if you took damage, destroy Horde creatures if you destroyed them. You can press the 'See attackers' button <strong>(3)</strong> if you need help to keep track of which creatures you have/haven't blocked.</p>
                                <p>- The Horde turn is over, it's now the Survivors turn.</p>
                                <h2>Survivors turn</h2>
                                <p>- Survivors play their turn together.</p>
                                <p>- The Horde never blocks when Survivors are attacking. When the Horde takes damage, mill one card for each damage (click the library to mill a card)<strong>(4)</strong>.</p>
                                <p>- Survivors win if the Horde library is empty and the Horde doesn't control any creatures (except creatures with the 'can't attack' tag).</p>

                            </div>) : (<div></div>)}
                            {panel == 2 ? (<div class="help">
                                <h1>The Horde rules</h1>
                                <h2>Rules by Peter Knudson</h2>
                                <p>The goal of Horde Magic is to survive the onslaught of creatures. The Horde deck has no life total (see Additional rules if you want the Horde to use lifepoints), so the only way to win is to... uh... not die. Eventually the deck will run out of cards and you'll be able to breath sight of relief.</p><p>In Horde Magic, the Horde has no decisions to make, and thus the Horde deck also doesn't require a pilot to run. This creates unique co-op gameplay, which many are likely to really enjoy. Additionally, you can battle the Horde deck solo.</p>

                                <h2>Starting the Game</h2>
                                <p>To play Horde Magic, each player needs a Commander deck. Any other Magic deck will do, but Horde Magic was developed to play with the multitude of Commander decks that people own.</p>

                                <h2>The Survivors</h2>
                                <p>There can be anywhere between one and four Survivors, which are the players teaming up to defend against the Horde. The number of Survivors determine the number of cards in the Horde deck, as the difficulty needs to scale accordingly. The Survivors have a collective life total of 20 life per player, and everybody loses when that life total becomes 0.</p>

                                <h2>The Horde</h2>
                                <p>The number of creatures you'll face over the course of the game is based on the number of Survivors. For less than 3 Survivors, take your 100 card Horde deck and remove between 25-75 cards (Using the deck size options).</p>

                                <h2>Game Play</h2>
                                <p>The Survivors get 3 turns to set up their defenses before the Horde takes a turn. Just like in Archenemy and Two-Headed Giant, the Survivors take turns simultaneously. After the 3 turn set-up, the Survivors and the Horde alternate turns.</p>
                                <p>On each of the Horde's turns, the top card of their library is flipped over. If the card is a creature token, then another is flipped over. Cards are flipped over until a non-token card is revealed. Sometimes the card is a Bad Moon, sometimes it's a Souless One, and sometimes it's even a Plague Wind. At that point, all of the tokens flipped this way are cast, and the non-token card is cast last.</p>
                                <p>Then the Horde enter combat. All its creatures come charging in without thinking twice. All creatures have haste and must attack each turn if able. That's just how they roll. Since the survivors are on a team together, when the Horde attack, they attack every player at the same time, just like in Two-Headed Giant, and when any of the survivors choose to block, they block for the whole team.</p>

                                <h2>Defeating the Horde</h2>
                                <p>You, as the Survivors, win when the Horde deck can't flip over anymore cards, and the Horde doesn't control any more creatures. You can use anything at your disposal to stem the bleeding, from Walls, to Wrath of Gods, to blocking with huge fatties. However, if you and your teammates feel that you have adequate defenses for the next attack, you can also attack the Horde at it's source. Creatures can't block, so it's safe to go on the offensive if you think you can survive the next wave.</p>
                                <p><strong>For each point of damage done to the Horde, the Horde mills one card off the top of their deck.</strong></p>

                                <h2>Winning</h2>
                                <p>The Survivors are victorious when all the creatures in play are dead, and the Horde deck has run out of cards. The Horde wins when the Survivors' life total becomes 0.</p>

                                <h2>Rules Notes</h2>
                                <p>The Horde deck is built so that, hopefully, the Horde is not presented with any decisions. However, there are lots of cards that the Survivors might play that cause the Horde to make a choice (such as Fact or Fiction or Chainer's Edict). In this case, the Horde makes this choice as randomly as possible.</p>
                                <p>The creature tokens and cards from the Horde deck use the stack, so you can respond to them coming into to play, or counter them.</p>
                                <p>The Horde never use any activated abilities of permanents it controls unless stated by the deck special rules.</p>
                                <p>The Horde has infinite mana, so cards like Propaganda and Mana Leak don't work. Sorry! The Horde always pays kicker or any other additional cost.</p>
                                <p>If you return a permanent to the Horde's hand, it gets cast again on their next turn.</p>
                                <p>There are a LOT of Magic cards in existence. If something doesn't work the way it's supposed to, just come up with the most fair way to execute the card. If you can't, cycle it. This is a casual format.</p>
                                <p>If the horde has to choose a target, it choose the BEST target. BEST is higher strength, then higher Mana Value. If still multiple possible targets, targets randomly</p>
                                <p>If survivors have planeswalkers, each time a creature controlled by the horde isn't blocked, it deals damage to the planeswalker. Target the planeswalker with highest Mana Value first if survivors have multiple planeswalkers.</p>

                                <h2>Additional rules</h2>
                                <p>Optional : the Horde starts with 20 lifepoints and only mills its library when his lifepoints are equal to 0.</p>
                                <p>Optional : each time a permanent or an ability controlled by the Horde deals damage to the survivors, the Horde gains that much life.</p>
                                <p><strong>Remember that this is a casual format, you can change as many rules as you want. What matters is that you have fun.</strong></p>
                            </div>) : (<div></div>)}
                            {panel == 3 ? (<div class="help">
                                <h1>FAQ</h1>

                                <h2>How to use the life counter ?</h2>
                                <p>When using a mouse, use the mouse wheel over a life counter to increase/decrease life points.</p>
                                <p>Swipe up/down to increase/decrease. If you want to make a small change, press the upper part to increase, or the bottom part to decrease</p>

                                <h2>How to mill the horde ?</h2>
                                <p>Press the horde's library to mill his deck.</p>

                                <h2>How to destroy a permenent ?</h2>
                                <p>Touch a card on the board to destroy it. To bring back a permanent from the graveyard, touch the graveyard then touch the card you want to return to the battlefield.</p>

                                <h2>How to handle the Horde's hand ?</h2>
                                <p>Press the 'return to hand' button to enable/disable the 'return to hand' mode. While the mode is enable, press a card on the battlefield to return it to the horde's hand.</p>
                                <p>Press the Draw One button to make the horde draw a card.</p>
                                <p>Press a card in the horde's hand to make the horde discard it.</p>
                                <p>The Horde will cast spells in his hand the next time you press the 'Draw' button.</p>

                                <h2>How to add/remove counters on cards ?</h2>
                                <p>Press the +/- buttons to enable/disable the 'add/remove' mode. While the 'add' mode is enable, press a card on the battlefield to add a counter to it. While the 'remove' mode is enable, press a card on the battlefield to remove a counter to it.</p>

                                <h2>How to zoom ?</h2>
                                <p>Hold your click on a card to zoom.</p>

                                <h2>How to make a specific action with a card on the board ?</h2>
                                <p>Move your mouse around the top right of the card to have the 'Quick action' menu button.</p>
                            </div>) : (<div></div>)}
                        </div>
                    </div>
                }
                hidePanel={onClick} />
        )
    }
}

function BlockerHandler(props) {
    const attackers = props.attackers

    function blocked(i) {
        let tmpArray = [...attackers]
        tmpArray[i].count -= 1
        if (tmpArray[i].count == 0) {
            tmpArray.splice(i, 1)
        }
        if (tmpArray.length == 0) {
            props.hidePanel()
        }
        props.updateAttackersArray(tmpArray)
    }

    function destroyed(card, i) {
        props.destroyed(card)
    }

    if (props.showPanel) {
        return (
            <FullScreenPanel
                title="Attackers"
                component={
                    <div class="attackersPanel d-flex flex-column">
                        <p class="mt-0">Creatures attacking you. Dismiss if the creature survives, destroy it if it doesn't.</p>
                        <CustomScrollContainer>
                            <div>
                                <div class="d-flex flex-row">
                                    {attackers.map(function (card, i) {
                                        return (
                                            <div class="d-flex flex-column" index={card.id} key={"Attackers_" + card.key} >
                                                <div class="card no-mdfc">
                                                    <CardIMG card={card} isFoil={card.cardCapacities.isFoil} />
                                                    <p class="cardCount">{card.count != 1 ? card.count : ""}</p>
                                                    {card.counters > 0 ? (<h1 class="blurred-background shadowed fadeIn">{card.counters}</h1>) : (<h1 />)}
                                                    {card.cardCapacities.cantAttackOrBlock ? (<p class="cantAttackOrBlock shadowed blurred-background">Can't attack</p>) : (<div></div>)}
                                                </div>
                                                <div class={props.isSpectating ? "hidden" : ""}>
                                                    <div class="d-flex flex-row justify-content-around px-3">
                                                        <button onClick={() => { blocked(i) }} class="primary">Dismiss</button>
                                                        <button onClick={() => { destroyed(card, i) }} class="">Destroy</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </CustomScrollContainer>
                        {/*<BigButton text="Hide" clicked={props.hidePanel} />*/}
                    </div>
                }
                hidePanel={props.hidePanel} />
        )
    }
}

function ExilePanel(props) {
    const exile = props.exile

    if (props.showPanel) {
        return (
            <FullScreenPanel
                title="Exile"
                component={
                    <div class="attackersPanel d-flex flex-column">
                        <p class="mt-0">See and return cards from the exile.</p>
                        <CustomScrollContainer>
                            <div>
                                <div class="d-flex flex-row">
                                    {exile.map(function (card, i) {
                                        return (
                                            <div class="d-flex flex-column" index={card.id} key={"Exile_" + card.key} >
                                                <div class="card no-mdfc">
                                                    <CardIMG card={card} isFoil={card.cardCapacities.isFoil} />
                                                    <p class="cardCount">{card.count != 1 ? card.count : ""}</p>
                                                    {card.counters > 0 ? (<h1 class="blurred-background shadowed fadeIn">{card.counters}</h1>) : (<h1 />)}
                                                    {card.cardCapacities.cantAttackOrBlock ? (<p class="cantAttackOrBlock shadowed blurred-background">Can't attack</p>) : (<div></div>)}
                                                </div>
                                                <div class={props.isSpectating ? "hidden" : ""}>
                                                    <div class="d-flex flex-row justify-content-around px-3">
                                                        <button onClick={() => { props.cardFunctions.play(i) }} class="primary">Cast</button>
                                                        <button onClick={() => { props.cardFunctions.graveyard(i) }} class="">Graveyard</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </CustomScrollContainer>
                        {/*<BigButton text="Hide" clicked={props.hidePanel} />*/}
                    </div>
                }
                hidePanel={props.hidePanel} />
        )
    }
}

function Milestones(props) {
    const cardsToCast_milestone = props.cardsToCast_milestone
    const onClick = props.onClick

    if (cardsToCast_milestone.length > 0) {
        return (
            <FullScreenPanel
                title="Milestone"
                component={
                    <div class="cardsToCast no-mdfc">
                        <CustomScrollContainer>
                            <div>
                                <div>
                                    {cardsToCast_milestone.map(function (card, i) {
                                        return (
                                            <Card_Cast card={card} />
                                        )
                                    })}
                                </div>
                            </div>
                        </CustomScrollContainer>
                    </div>
                }
                hidePanel={onClick}
                fullScreenDismiss={true} />
        )
    }

    function Card_Cast(props) {
        const [card, setCard] = useState(props.card)

        return (
            <div class="card">
                <CardIMG card={card} isFoil={card.cardCapacities.isFoil} />
                <p class="cardCount">{card.count}</p>
            </div>
        )
    }
}

function AlternativeTokensInitializer(props) {
    /*
    const neededAlternativeTokens = props.neededAlternativeTokens
    const alternativeTokensData = JSON.parse(sessionStorage.getItem("alternativeTokensData"))

    const { data, loading, error } = useGoogleSheets({
        apiKey: "AIzaSyC1IFQjAzoPL7xKyyitEc7uK3_3P0Ql6bU",
        sheetId: "17N3Jbz7oGWCMQiHEivEqAbon1n7Cn6lI-OPUgya0tD0",
        sheetsOptions: [{ id: 'CatAndCantrips', headerRowIndex: 1 }],
    });

    if (alternativeTokensData == undefined) {
        if (loading == false) {
            if (error == null) {
                const sheetData = data[0]
                const alternativesArray = sheetData.data.slice(1, sheetData.data.length)

                let alternativeTokensNeeded = []
                for (const i in alternativesArray) {
                    neededAlternativeTokens.forEach(replacer => {
                        if (alternativesArray[i].cardId == replacer.oracleId) {
                            alternativeTokensNeeded.push({
                                cardId: replacer.cardId,
                                cardUrl: alternativesArray[i].cardUrl
                            })
                        }
                    });
                }

                sessionStorage.setItem("alternativeTokensData", JSON.stringify({
                    availableCardsReplacer: alternativeTokensNeeded
                }))
            } else {
                console.log(error)
            }
        }
    }
*/
    return (
        <div></div>
    )
}