import React, { useEffect, useState } from "react";
import DeckPicker from './DeckPicker/DeckPicker'
import GameReborn from './Game/GameReborn'
import { LandscapeOnly } from "./Commons/Commons";
import { DECKS } from "../utils/DB";

class Horde extends React.Component {

    constructor() {
        super()

        const userOptions = JSON.parse(sessionStorage.getItem("userOptions"))

        if (userOptions != undefined) {
            this.state = {
                isPlaying: false,
                selectedDeck: userOptions.selectedDeck,
                options: {
                    removeStrongPermanents: userOptions.options.removeStrongPermanents,
                    startWithPermanent: userOptions.options.startWithPermanent,
                    hordeheals: userOptions.options.hordeheals,
                    startLife: userOptions.options.startLife,
                    milestones: userOptions.options.milestones,
                    deckSize: userOptions.options.deckSize,
                    tokenMultiplicator: userOptions.options.tokenMultiplicator,
                    allowTokensHandAndGraveyard: userOptions.options.allowTokensHandAndGraveyard,
                    backgroundColor: userOptions.options.backgroundColor,
                    foilEffect: userOptions.options.foilEffect,
                    alternateDrawMode: userOptions.options.alternateDrawMode,
                    safezoneSize: userOptions.options.safezoneSize,
                    backgroundImageUrl: userOptions.options.backgroundImageUrl
                }
            }
        } else {
            let cookie = this.readOptionsCookie()
            console.log("Using cookie")
            console.log(cookie)
            if (cookie == null) {
                this.state = {
                    isPlaying: false,
                    selectedDeck: "INTRO",
                    options: {
                        removeStrongPermanents: true,
                        startWithPermanent: false,
                        hordeheals: false,
                        startLife: 60,
                        milestones: [false, false, false, false],
                        deckSize: 100,
                        tokenMultiplicator: 1,
                        allowTokensHandAndGraveyard: false,
                        backgroundColor: 1,
                        foilEffect: [false, false, false],
                        alternateDrawMode: -1,
                        safezoneSize: 1,
                        backgroundImageUrl: ""
                    }
                }
            } else {
                this.state = {
                    isPlaying: false,
                    selectedDeck: "INTRO",
                    options: cookie
                }
            }
        }
    }

    setIsPlaying = (value) => {
        this.setState({ isPlaying: value })
    }

    setSelectedDeck = (deck) => {
        this.setState({ selectedDeck: deck })

        sessionStorage.setItem("userOptions", JSON.stringify({
            options: this.state.options,
            selectedDeck: deck
        }))
    }

    setOptions = (options) => {
        this.setState({ options: options })

        sessionStorage.setItem("userOptions", JSON.stringify({
            options: options,
            selectedDeck: this.state.selectedDeck
        }))

        this.bakeOptionsCookie(options)
    }

    bakeOptionsCookie = (options) => {
        let date = new Date(new Date().getTime()+60*60*1000*24*31)
        date = date.toUTCString()
        var cookie = ["options", '=', JSON.stringify(options), /*'; domain=.', window.location.host.toString(), */'; path=/;', 'expires=', date, ";"].join('');
        document.cookie = cookie;
    }

    readOptionsCookie = () => {
        var result = document.cookie.match(new RegExp("options" + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    render() {
        if (this.state.isPlaying) {
            return <LandscapeOnly component={<GameReborn selectedDeck={this.state.selectedDeck} setIsPlaying={this.setIsPlaying} options={this.state.options} />} />;
        }
        return <DeckPicker setIsPlaying={this.setIsPlaying} setSelectedDeck={this.setSelectedDeck} setOptions={this.setOptions} options={this.state.options} selectedDeck={this.state.selectedDeck} />
    }
}

export default Horde