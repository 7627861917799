import React from "react"
import './Commons.css'

export function CustomScrollContainer(props) {
    const id = "id" + Math.random().toString(16).slice(2)
    const scrollSpeed = props.scrollSpeed != undefined ? props.scrollSpeed : 1

    const onWheel = e => {
        //e.preventDefault();
        const container = document.getElementById('container_' + id);
        const containerScrollPosition = document.getElementById('container_' + id).scrollLeft;
        container.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY * 1.1,
            behaviour: "instant"
        });
    };

    return (
        <div id={'container_' + id} class="sideways-scroll scroll-container" onWheel={onWheel}>
            <div class="mouseWheelOverlay"></div>
            {props.children}
        </div>
    )
}

export default CustomScrollContainer