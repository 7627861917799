import React from "react";
import { useParams } from "react-router-dom";
import DeckEditor from "./DeckEditor";

export function DeckEditorLink(props) {
    const { id } = useParams();

    return (
        <DeckEditor deckId={id}/>
    )
}

export default DeckEditorLink