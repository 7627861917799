import GameReborn from "../GameReborn";
import { useParams } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { useEffect } from "react";

const supabase = createClient("https://gwgpryjgctjtyldxxvbj.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd3Z3ByeWpnY3RqdHlsZHh4dmJqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcyMjMyNzEsImV4cCI6MjAyMjc5OTI3MX0.mh5FD9z7bpUwR5QGZRMVvtnb-yjnosIn4_FDbHniMSE");
const { useState } = require("react")

export default function MultiplayerGame(props) {
    const { gameId } = useParams();
    const [group_setup, setGroup_setup] = useState({
        options: false,
        selectedDeck: false,
        tokens: [],
        deckSizeAtStart: 100
    })
    const [isGameReady, setIsGameReady] = useState(false)

    const [board, setBoard] = useState([])
    const [deck, setDeck] = useState([])
    const [lifepoints, setLifepoints] = useState(0)
    const [group_graveyard, setGroup_graveyard] = useState({
        graveyard: [],
        lastMilledToken: false,
        millCount: 0
    })
    const [group_newTurn, setGroup_newTurn] = useState({
        turnNumber: 0,
        cardsToCast_fromLibrary: [],
        cardsToCast_fromGraveyard: [],
        cardsToCast_fromHand: [],
        cardsToCast_milestone: []
    })
    const [group_other, setGroup_other] = useState({
        hand: [],
        showTopCard: false,
        attackers: [],
        showAttackers: false
    })

    useEffect(() => {
        getGameIniData();
        // reutn cleanup
        //return () => {supabase.removeChannel("Game")}
        return () => { supabase.removeAllChannels(); }
    }, []);

    async function getGameIniData() {
        console.log("Playing game " + gameId)


        /*
        supabase
            .channel("Game")
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'Game', filter: 'gameId=eq.' + gameId }, handleInserts)
            .subscribe()
*/
        supabase
            .channel("GroupSetupTable")
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'GroupSetupTable', filter: 'gameId=eq.' + gameId }, handleInserts)
            .subscribe()

        supabase
            .channel("BoardTable")
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'BoardTable', filter: 'gameId=eq.' + gameId }, handleInserts)
            .subscribe()

        supabase
            .channel("DeckTable")
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'DeckTable', filter: 'gameId=eq.' + gameId }, handleInserts)
            .subscribe()

        supabase
            .channel("GroupGraveyardTable")
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'GroupGraveyardTable', filter: 'gameId=eq.' + gameId }, handleInserts)
            .subscribe()

        supabase
            .channel("GroupNewTurnTable")
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'GroupNewTurnTable', filter: 'gameId=eq.' + gameId }, handleInserts)
            .subscribe()

        supabase
            .channel("GroupOtherTable")
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'GroupOtherTable', filter: 'gameId=eq.' + gameId }, handleInserts)
            .subscribe()

        supabase
            .channel("LifepointsTable")
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'LifepointsTable', filter: 'gameId=eq.' + gameId }, handleInserts)
            .subscribe()
        
        await supabase.from("GroupSetupTable").select("group_setup").eq("gameId", gameId).then((data, error) => {
            setGroup_setup(data.data[0].group_setup)
            setIsGameReady(true)
        }).catch((error) => {
            console.log(error)
        })

        await supabase.from("BoardTable").select("board").eq("gameId", gameId).then((data, error) => {
            setBoard(data.data[0].board)
        }).catch((error) => {
            console.log(error)
        })

        await supabase.from("DeckTable").select("deck").eq("gameId", gameId).then((data, error) => {
            setDeck(data.data[0].deck)
        }).catch((error) => {
            console.log(error)
        })

        await supabase.from("GroupGraveyardTable").select("group_graveyard").eq("gameId", gameId).then((data, error) => {
            setGroup_graveyard(data.data[0].group_graveyard)
        }).catch((error) => {
            console.log(error)
        })

        await supabase.from("GroupNewTurnTable").select("group_newTurn").eq("gameId", gameId).then((data, error) => {
            setGroup_newTurn(data.data[0].group_newTurn)
        }).catch((error) => {
            console.log(error)
        })

        await supabase.from("GroupOtherTable").select("group_other").eq("gameId", gameId).then((data, error) => {
            console.log(data)
            setGroup_other(data.data[0].group_other)
        }).catch((error) => {
            console.log(error)
        })

        await supabase.from("LifepointsTable").select("lifepoints").eq("gameId", gameId).then((data, error) => {
            setLifepoints(data.data[0].lifepoints)
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleInserts = (payload) => {
        console.log('Change received!', payload)
        if (payload.new.board) {
            setBoard(payload.new.board)
        }
        if (payload.new.deck) {
            setDeck(payload.new.deck)
        }
        if (payload.new.group_graveyard) {
            setGroup_graveyard(payload.new.group_graveyard)
        }
        if (payload.new.group_newTurn) {
            setGroup_newTurn(payload.new.group_newTurn)
        }
        if (payload.new.group_other) {
            setGroup_other(payload.new.group_other)
        }
        if (payload.new.group_setup) {
            setIsGameReady(true)
            setGroup_setup(payload.new.group_setup)
        }
        if (payload.new.lifepoints) {
            setLifepoints(payload.new.lifepoints)
        }
    }

    if (isGameReady) {
        return (
            <GameReborn selectedDeck={group_setup.selectedDeck}
                setIsPlaying={props.setIsPlaying}
                options={group_setup.options}
                cloudValues={{
                    group_setup: group_setup,
                    board: board,
                    deck: deck,
                    lifepoints: lifepoints,
                    group_graveyard: group_graveyard,
                    group_newTurn: group_newTurn,
                    group_other: group_other
                }}
            >
            </GameReborn>
        )
    }
}