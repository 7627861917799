import ScrollContainer from 'react-indiana-drag-scroll'
import React, { useEffect, useState } from "react";
import './DeckPicker.css'
import { Navbar, BigButton, DeckArt } from '../Commons/Commons.jsx'
import ReactDOM from "react-dom/client";
import DeckEditor from '../DeckEditor/DeckEditor';
import Options from '../Commons/Options';
import { getDoc, collection, doc, getDocs, query, where, documentId, setDoc } from "firebase/firestore";
import { db, auth } from '../../firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth'
const { DECKS } = require("../../utils/DB");


class DeckPicker extends React.Component {
    constructor(props) {
        super(props)

        var decks = DECKS

        const userData = JSON.parse(sessionStorage.getItem("userData"))

        if (userData == undefined || userData.decks == undefined || userData.shouldUpdateDecks) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.iniUserDecks(user.uid)
                }
            })
        } else {
            decks = userData.decks
        }

        let oldSelectedDeck = "INTRO"
        if (props.selectedDeck != "INTRO") {
            for (let i = 0; i < decks.length; i++) {
                if (decks[i].deckId == props.selectedDeck.deckId) {
                    oldSelectedDeck = decks[i]
                }
            }
        }

        this.state = {
            selectedDeck: oldSelectedDeck,
            artistName: "Greg Opalinski",
            options: props.options,
            customDeckListIsValid: false,
            userDecks: decks,
            userDecksLoaded: false
        };
    }

    iniUserDecks = async (uid) => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))

        if (userData == undefined || userData.decks == undefined || userData.shouldUpdateDecks) {
            if (userData != undefined && userData.userCollection != undefined) {
                console.log("Collection already downloaded")
                this.iniDecksWithCollection(userData.userCollection, uid)
            } else {
                await getDoc(doc(db, "usersCollection", uid))
                    .then(async (userDeckIdsSnapshot) => {
                        console.log("CALL - iniUserDecks - getCollection")
                        const decksCollection = userDeckIdsSnapshot.data().collection
                        this.iniDecksWithCollection(decksCollection, uid)
                    })
            }
        } else {
            console.log("Deck deja dl")
            this.setState({ userDecks: userData.decks })
        }
    }

    iniDecksWithCollection = async (decksCollection, uid) => {
        const batches = [];
        const decksCollectionTmp = [...decksCollection]

        while (decksCollectionTmp.length) {
            batches.push(decksCollectionTmp.splice(0, 20));
        }

        Promise.all(batches.map(batch => {
            return new Promise((resolve, reject) => {

                const documents = [];

                getDocs(query(collection(db, "decks"), where(documentId(), "in", batch)))
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            documents.push({ ...doc.data(), deckId: doc.id });

                        });
                        console.log(documents)
                        resolve(documents);
                    }).catch((error) => {
                        reject(error);
                    });
            });
        }))
            .then(async decksDocs => {
                // Les résultats de chaque appel sont disponibles ici
                let decksData = decksDocs.flat()
                console.log(decksCollection)
                console.log("CALL - iniUserDecks - getDecks")

                // Order decks
                let decksDataOrdered = []
                decksCollection.forEach(deckId => {
                    decksData.forEach(deck => {
                        if (deckId == deck.deckId) {
                            decksDataOrdered.push(deck)
                        }
                    })
                })

                this.setState({ userDecks: decksDataOrdered })
                this.state.userDecksLoaded = true

                // If some decks have been removed, remove them from collection
                let collectionWithoutMissingDecks = []
                decksDataOrdered.forEach(deck => {
                    collectionWithoutMissingDecks.push(deck.deckId)
                })

                if (collectionWithoutMissingDecks.length < decksCollection.length) {
                    console.log("Decks are missing, removing them from collection")
                    console.log(collectionWithoutMissingDecks)
                    await setDoc(doc(db, "usersCollection", uid), {
                        collection: collectionWithoutMissingDecks
                    });
                }

                sessionStorage.setItem("userData", JSON.stringify({
                    uid: uid,
                    decks: decksDataOrdered,
                    shouldUpdateDecks: false,
                    userCollection: decksCollection
                }))
            })
            .catch(error => {
                // En cas d'erreur lors de l'un des appels
                console.error(error);
            });
    }

    selectDeck = (deck) => {
        console.log("Selecting deck " + deck.name)
        this.setState({ selectedDeck: deck })
        this.props.setSelectedDeck(deck)

        if (deck == "INTRO") {
            this.setState({ artistName: "Greg Opalinski" })
        } else if (deck == "IMPORT") {
            this.setState({ artistName: "Piotr Dura" })
        } else {
            if (deck.image) {
                this.setState({ artistName: deck.artistName })
            } else {
                this.setState({ artistName: "" })
            }
        }
    }

    startGame = () => {
        if (this.state.selectedDeck == "IMPORT" && !this.state.customDeckListIsValid) {
            return
        }
        this.props.setIsPlaying(true)
    }

    checkCustomDeckList = () => {
        if (this.state.selectedDeck == "IMPORT") {
            let deckList = document.getElementById("custom-decklist").value
            if (deckList.includes("## Horde Deck ##") && deckList.includes("## Too Strong ##") && deckList.includes("## Available Tokens ##") && deckList.includes("## Weak Permanents ##") && deckList.includes("## Powerfull Permanents ##")) {
                let deck = {
                    name: "IMPORT",
                    deckList: deckList
                }
                this.props.setSelectedDeck(deck)
                this.setState({ customDeckListIsValid: true })
            } else {
                this.setState({ customDeckListIsValid: false })
            }
        }
    }

    onChangeRemoveStrongPermanents = () => {
        let options = this.state.options
        options.removeStrongPermanents = !options.removeStrongPermanents
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeStartWithPermanents = () => {
        let options = this.state.options
        options.startWithPermanent = !options.startWithPermanent
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeHordeHeals = () => {
        let options = this.state.options
        options.hordeheals = !options.hordeheals
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeMilestones = (milestone) => {
        let options = this.state.options
        options.milestones[milestone] = !options.milestones[milestone]
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeStartLife = (value) => {
        let options = this.state.options
        options.startLife = value
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeDeckSize = (value) => {
        let options = this.state.options
        options.deckSize = value
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeTokenMultiplicator = (value) => {
        let options = this.state.options
        options.tokenMultiplicator = value
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeAllowTokensHandAndGraveyard = () => {
        let options = this.state.options
        options.allowTokensHandAndGraveyard = !options.allowTokensHandAndGraveyard
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeBackgroundColor = (value) => {
        let options = this.state.options
        options.backgroundColor = value
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeFoilEffect = (value) => {
        let options = this.state.options
        options.foilEffect = value
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeAlternateDrawMode = () => {
        let options = this.state.options
        options.alternateDrawMode = options.alternateDrawMode == -1 ? 1 : -1
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeSafezoneSize = (value) => {
        let options = this.state.options
        options.safezoneSize = value
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    onChangeBackgroundImageUrl = (value) => {
        let options = this.state.options
        options.backgroundImageUrl = value
        this.props.setOptions(options)
        this.setState({ options: options })
    }

    openDeckEditor = () => {
        const myWindow = window.open('', '_blank');
        const root = ReactDOM.createRoot(document.getElementById("root"));
        root.render(<DeckEditor />, myWindow.document.body);

        myWindow.document.close();
        myWindow.focus();
        myWindow.close();
    }

    render() {
        return (
            <div id="DeckPicker" class="fullscreen">
                <div class="background">
                    {(() => {
                        if (this.state.selectedDeck != "IMPORT" && this.state.selectedDeck != "INTRO") {
                            return (
                                this.state.selectedDeck.image ?
                                    (<img src={require('./../../assets/' + this.state.selectedDeck.image)} />) :
                                    (<DeckArt artId={this.state.selectedDeck.artId} />)
                            )
                        } else if (this.state.selectedDeck == "INTRO") {
                            return (<img src={require('./../../assets/img_intro.jpg')} />)
                        } else {
                            return (<img src={require('./../../assets/img_import.jpg')} />)
                        }
                    })()}
                    <div class="vignette"></div>
                </div>
                <div class="foreground fullscreen">
                    <Navbar selected="Home" showNavbarByDefault={true} />
                    <div class="main landscape-only">
                        {(() => {
                            if (this.state.selectedDeck != "INTRO") {
                                return (
                                    <div class="deckPicked blurred-background">
                                        <div class="image">
                                            {
                                                this.state.selectedDeck.image ?
                                                    (<img src={require('./../../assets/' + this.state.selectedDeck.image)} />) :
                                                    (<DeckArt artId={this.state.selectedDeck.artId} classes="shadowed" />)
                                            }
                                            <BigButton clicked={this.startGame} text="Play" />
                                            <h1>{this.state.selectedDeck.name}</h1>
                                            {
                                                this.state.selectedDeck.intro.trim().length >= 2 ?
                                                    (<div class="info">
                                                        <p>{this.state.selectedDeck.intro}</p>
                                                        <h2>{this.state.selectedDeck.specialRules == "" ? "" : "Special rules"}</h2>
                                                        <p>{this.state.selectedDeck.specialRules}</p>
                                                    </div>) :
                                                    (<div class="info">
                                                        <h2 class="mt-0">{this.state.selectedDeck.specialRules == "" ? "" : "Special rules"}</h2>
                                                        <p>{this.state.selectedDeck.specialRules}</p>
                                                    </div>)
                                            }
                                        </div>
                                        <Options options={this.state.options} functions={{
                                            onChangeRemoveStrongPermanents: this.onChangeRemoveStrongPermanents,
                                            onChangeStartWithPermanents: this.onChangeStartWithPermanents,
                                            onChangeMilestones: this.onChangeMilestones,
                                            onChangeHordeHeals: this.onChangeHordeHeals,
                                            onChangeStartLife: this.onChangeStartLife,
                                            onChangeDeckSize: this.onChangeDeckSize,
                                            onChangeTokenMultiplicator: this.onChangeTokenMultiplicator,
                                            onChangeAllowTokensHandAndGraveyard: this.onChangeAllowTokensHandAndGraveyard,
                                            onChangeBackgroundColor: this.onChangeBackgroundColor,
                                            onChangeFoilEffect: this.onChangeFoilEffect,
                                            onChangeAlternateDrawMode: this.onChangeAlternateDrawMode,
                                            onChangeSafezoneSize: this.onChangeSafezoneSize,
                                            onChangeBackgroundImageUrl: this.onChangeBackgroundImageUrl
                                        }}
                                        />
                                    </div>
                                )
                            } else if (this.state.selectedDeck == "INTRO") {
                                return (
                                    <div class="deckPicked intro">
                                        <div class="image">
                                        <h1>Welcome</h1>
                                            <img src={require('./../../assets/img_intro.jpg')} class="intro-image" />
                                            <div class="copyright">
                                                <h1>{this.state.artistName ? "art by " + this.state.artistName : ""}</h1>
                                                <p>©Wizards of the Coast LLC</p>
                                            </div>
                                        </div>
                                        <div class="options blurred-background overflow-auto">
                                            <label>The horde is a cooperative way to play tabletop Magic the Gathering for 1 to 4 players against an automated opponent.</label>
                                            <h2>What is Against the horde ?</h2>
                                            <p>Against the Horde is an app that allows players to easily enjoy the horde format. Take your favorite deck, press the play button and try to survive !</p>
                                            <p>Each player needs a real Magic the Gathering deck, this app only handle the role of the automated opponent.</p>
                                            <p>Don't know how to play ? Press the 'Help' button when playing to read the rules and learn how to use the app.</p>
                                            <p><i>This web app is an unofficial Fan Content permitted under the Fan Content Policy. Not approved/endorsed by Wizards. Portions of the materials used are property of Wizards of the Coast. ©Wizards of the Coast LLC.</i></p>
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                        <div class="deckpicker-bar d-flex flex-column">
                            <h3>Pick a deck</h3>
                            <div class="scroll-wrapper">
                                {this.state.userDecks.map(function (deck, i) {
                                    return (
                                        <DeckPicker_Deck deck={deck} selectDeck={this.selectDeck} key={"Deck_" + i} />
                                    )
                                }, this)}
                            </div>
                        </div>
                    </div>
                    <div class="fullscreen portrait-message">
                        <p>This app only works in landscape mode.</p>
                    </div>
                </div>
            </div>)
    }
}

function DeckPicker_Deck(props) {
    const deck = props.deck

    return (
        <button class="deckPicker" onClick={() => { props.selectDeck(deck) }}>
            {deck.image ?
                (<img src={require('./../../assets/' + deck.image)} />) :
                (<DeckArt artId={deck.artId} />)
            }
            <div><p>{deck.name}</p></div>
        </button>
    )
}

export default DeckPicker