export const DECKS = [
    {
        name: "Zombie",
        deckList: "deck_zombie.txt",
        image: "img_zombie.jpg",
        artistName: "Grzegorz Rutkowski",
        intro: "The original horde deck by Peter Knudson.",
        specialRules: "All creatures controlled by the Horde have haste",
        isOffline: true,
        deckId: "STARTER_Zombie"
    },
    {
        name: "Dinosaur",
        deckList: "deck_dinosaur.txt",
        image: "img_dinosaur.jpg",
        artistName: "Grzegorz Rutkowski",
        intro: "A modified version of the Dinosaur Rage deck by TenkayCrit.",
        specialRules: "All creatures controlled by the Horde have haste.",
        isOffline: true,
        deckId: "STARTER_Dinosaur"
    },
    {
        name: "Nature",
        deckList: "deck_nature.txt",
        image: "img_nature.jpg",
        artistName: "Grzegorz Rutkowski",
        intro: "For token lovers.",
        specialRules: "All tokens controlled by the Horde have haste.",
        isOffline: true,
        deckId: "STARTER_Nature"
    },
    {
        name: "Sliver",
        deckList: "deck_sliver.txt",
        image: "img_sliver.jpg",
        artistName: "Aleksi Briclot",
        intro: "A modified version of the Sliver Hive deck by TenkayCrit.",
        specialRules: "All creatures controlled by the Horde have haste. All of the artifact slivers in the Horde deck are treated as tokens.",
        isOffline: true,
        deckId: "STARTER_Sliver"
    },
    {
        name: "Human",
        deckList: "deck_human.txt",
        image: "img_human.jpg",
        artistName: "Antonio José Manzanedo",
        intro: "A modified version of the Armies of Men deck by TenkayCrit.",
        specialRules: "All creatures controlled by the Horde have haste and are Humans in addition to their other creature types. All tokens controlled by the Horde are white",
        isOffline: true,
        deckId: "STARTER_Human"
    },
    {
        name: "Phyrexian",
        deckList: "deck_phyrexian.txt",
        image: "img_phyrexian.jpg",
        artistName: "Igor Kieryluk",
        intro: "A modified version of the Phyrexian Perfection deck by TenkayCrit.",
        specialRules: "All creatures controlled by the Horde have haste. The Survivors share poison counters. They do not lose the game for having 10 or more poison counters. Every time the Survivors gain one or more poison counters, each Survivor exiles 1 card from the top of each of their libraries face down for each poison counter.",
        isOffline: true,
        deckId: "STARTER_Phyrexian"
    },
    {
        name: "Eldrazi",
        deckList: "deck_eldrazi.txt",
        image: "img_eldrazi.jpg",
        artistName: "Aleksi Briclot",
        intro: "A modified version of the Eldrazi Horror deck by TenkayCrit.",
        specialRuless: "All tokens controlled by the Horde have haste. All eldrazi spawn the Horde controls cannot attack or block. If the Horde controls 10 eldrazi spawn at the start of its precombat main phase, they are sacrificed, and the Horde casts the three eldrazi titans from exile.",
        specialRules: "All tokens controlled by the Horde have haste.\n\n All eldrazi spawn the Horde controls cannot attack or block. If the Horde controls 10 eldrazi spawn at the start of its precombat main phase, they are sacrificed, and the Horde casts the three eldrazi titans from exile.",
        isOffline: true,
        deckId: "STARTER_Eldrazi"
    }
];
