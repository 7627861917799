import './CollectionManager.css'
import React, { useCallback, useEffect, useState } from "react";
import { Button, UserName, DeckArt, Footer } from '../Commons/Commons.jsx'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { getDoc, setDoc, collection, doc, getDocs, query, where, documentId, addDoc, updateDoc, arrayUnion, deleteDoc, arrayRemove } from "firebase/firestore";
import { db, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth'
import NewNavBar from '../HomePage/NewNavBar.jsx';


class CollectionManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            decks: [],
            userDecksLoaded: false
        }

        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.iniUserDecks(user.uid)
            }
        })
    }

    iniUserDecks = async (uid) => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))

        if (userData == undefined || userData.decks == undefined || userData.shouldUpdateDecks) {
            if (userData != undefined && userData.userCollection != undefined) {
                console.log("Collection already downloaded")
                this.iniDecksWithCollection(userData.userCollection, uid)
            } else {
                await getDoc(doc(db, "usersCollection", uid))
                    .then(async (userDeckIdsSnapshot) => {
                        console.log("CALL - iniUserDecks - getCollection ")
                        const decksCollection = userDeckIdsSnapshot.data().collection
                        this.iniDecksWithCollection(decksCollection, uid)
                    })
            }
        } else {
            console.log("Deck deja dl")
            this.setState({ decks: userData.decks })
        }
    }

    iniDecksWithCollection = async (decksCollection, uid) => {
        const batches = [];
        const decksCollectionTmp = [...decksCollection]

        while (decksCollectionTmp.length) {
            batches.push(decksCollectionTmp.splice(0, 20));
        }

        Promise.all(batches.map(batch => {
            return new Promise((resolve, reject) => {

                const documents = [];

                getDocs(query(collection(db, "decks"), where(documentId(), "in", batch)))
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            documents.push({ ...doc.data(), deckId: doc.id });

                        });

                        resolve(documents);
                    }).catch((error) => {
                        reject(error);
                    });
            });
        }))
            .then(async decksDocs => {
                // Les résultats de chaque appel sont disponibles ici
                let decksData = decksDocs.flat()
                console.log(decksCollection)
                console.log("CALL - iniUserDecks - getDecks")

                // Order decks
                let decksDataOrdered = []
                decksCollection.forEach(deckId => {
                    decksData.forEach(deck => {
                        if (deckId == deck.deckId) {
                            decksDataOrdered.push(deck)
                        }
                    })
                })

                this.setState({ decks: decksDataOrdered })
                this.state.userDecksLoaded = true

                // If some decks have been removed, remove them from collection
                let collectionWithoutMissingDecks = []
                decksDataOrdered.forEach(deck => {
                    collectionWithoutMissingDecks.push(deck.deckId)
                })

                if (collectionWithoutMissingDecks.length < decksCollection.length) {
                    console.log("Decks are missing, removing them from collection")
                    console.log(collectionWithoutMissingDecks)
                    await setDoc(doc(db, "usersCollection", uid), {
                        collection: collectionWithoutMissingDecks
                    });
                }

                sessionStorage.setItem("userData", JSON.stringify({
                    uid: uid,
                    decks: decksDataOrdered,
                    shouldUpdateDecks: false,
                    userCollection: decksCollection
                }))
            })
            .catch(error => {
                // En cas d'erreur lors de l'un des appels
                console.error(error);
            });

    }

    render() {
        /*
        return(
        <div>
            <UserName displayName="Thank you" uid="lvl-1" />
            <UserName displayName="Thank you" uid="lvl-2" />
            <UserName displayName="Thank you" uid="lvl-3" />
        </div>)
        */
        return (
            <div id="CollectionManager" class="d-flex flex-column container-fluid p-0">
                <NewNavBar selected="Collection" />
                <div class="main">
                    <Container className='mt-4'>
                        <Row className='text-white'>
                            <Col md={10} className='home-title text-start'>
                                <h1>Manage your decks</h1>
                                <p>Create a new deck or edit yours. To modify a deck made by someone else, duplicate it then edit the copy.</p>
                            </Col>
                            <Col md={2}>
                                <Link to="/deck" className="new-deck deck shadowed deck-hover-animated">
                                    <h1>Create new deck</h1>
                                    <div class="deckVignette"> </div>
                                    <img src={"https://api.scryfall.com/cards/" + "a12b16b0-f75f-42d8-9b24-947c1908e0f7" + "?format=img&version=art_crop"} class="shadowed" />
                                </Link>
                            </Col>
                        </Row>
                        <hr class="mt-4"></hr>
                    </Container>
                    <div class="overflow-auto">
                        <Container mb={5}>
                            <Row>
                                {this.state.decks.map(function (deck, i) {
                                    return (
                                        <DeckCollection_Deck deck={deck} key={i} i={i} />
                                    )
                                }, this)}
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer></Footer>
            </div>)
    }
}

function DeckCollection_Deck(props) {
    const deckInfo = props.deck
    const [hideDuplicate, setHideDuplicate] = useState(false)
    const [hideDelete, setHideDelete] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    async function deleteDeck() {
        if (!showDeleteConfirmation) {
            setShowDeleteConfirmation(true)
            return
        }
        setHideDelete(true)
        setShowDeleteConfirmation(false)

        // If owner, delete from db
        if (auth.currentUser.uid == deckInfo.authorUuid) {
            await deleteDoc(doc(db, "decks", deckInfo.deckId))
        }

        const collectionRef = doc(db, "usersCollection", auth.currentUser.uid)
        await updateDoc(collectionRef, {
            collection: arrayRemove(deckInfo.deckId)
        }).then((doc) => {
            const userData = JSON.parse(sessionStorage.getItem("userData"))
            let decks = userData.decks
            let collection = userData.userCollection
            decks.splice(props.i, 1)
            collection.splice(props.i, 1)
            sessionStorage.setItem("userData", JSON.stringify({
                uid: userData.uid,
                decks: decks,
                shouldUpdateDecks: userData.shouldUpdateDecks,
                userCollection: collection
            }))
            window.location.reload(false)
        });
    }

    async function duplicateDeck() {
        setHideDuplicate(true)
        let newName = deckInfo.name
        if (!newName.includes(" - Copy")) {
            newName += " - Copy"
        }
        var deckObject = {
            artId: deckInfo.artId,
            author: auth.currentUser.displayName,
            authorUuid: auth.currentUser.uid,
            deckList: deckInfo.deckList,
            name: newName,
            intro: deckInfo.intro,
            specialRules: deckInfo.specialRules,
            lastModified: new Date(),
            nameKeywords: deckInfo.name.toLowerCase().split(' ').filter(function (e) { return e !== '' && e.length > 1 }),
            public: false
        }

        try {
            const deckRef = await addDoc(collection(db, "decks"), deckObject)
            console.log("Document written with ID: ", deckRef.id)

            const collectionRef = doc(db, "usersCollection", auth.currentUser.uid)
            await updateDoc(collectionRef, {
                collection: arrayUnion(deckRef.id)
            }).then((doc) => {
                const userData = JSON.parse(sessionStorage.getItem("userData"))
                let decks = userData.decks
                let collection = userData.userCollection
                deckObject["deckId"] = deckRef.id
                decks.push(deckObject)
                collection.push(deckRef.id)
                sessionStorage.setItem("userData", JSON.stringify({
                    uid: userData.uid,
                    decks: decks,
                    shouldUpdateDecks: userData.shouldUpdateDecks,
                    userCollection: collection
                }))
                window.location.reload(false)
            });
        } catch (e) {
            console.error("Error adding document: ", e)
        }
    }

    return (
        <Col md={6} lg={4} xxl={3}>
            <div className='deckCol mb-4'>
                <Link className="deck shadowed deck-hover-animated" to={"/Deck/" + deckInfo.deckId} >
                    <div class="deck-info">
                        <h1 class="d-block">{deckInfo.name}</h1>
                        <UserName uid={deckInfo.authorUuid} displayName={deckInfo.author} />
                    </div>
                    <div>
                        {
                            auth.currentUser.uid == deckInfo.authorUuid ? (<img class="deck-edit-icon" src={require("../../assets/Icons/edit.png")} />) : (<div></div>)
                        }
                    </div>
                    <div class="blurred-background"></div>
                    <div class="deckVignette"> </div>
                    <DeckArt artId={deckInfo.artId} />

                </Link>
                <div class="d-flex justify-content-around duplicate-delete-row">
                    <Button text="Duplicate" clicked={duplicateDeck} classes={(hideDuplicate ? " invisible" : "") + " w-50 me-1"} />
                    <Button text={showDeleteConfirmation ? "Sure ?" : (auth.currentUser.uid == deckInfo.authorUuid ? "Delete" : "Remove")} clicked={deleteDeck} classes={(hideDelete ? " invisible" : "") + (showDeleteConfirmation ? " primary" : "") + " w-50 ms-1"} />
                </div>
            </div>
        </Col>
    )
}

export default CollectionManager