import React, { useEffect, useState } from "react";
import GameReborn from "../../Game/GameReborn"
import MultiplayerHostGame from "../../Game/Multiplayer/MultiplayerHostGame"
import { LandscapeOnly, BigButton, DeckArt } from "../Commons";
import { DECKS } from "../../../utils/DB";
import Options from "../Options";
import './PlayablePanel.css'

class PlayablePanel extends React.Component {

    constructor(props) {
        super(props)
        console.log("Ini play panel")
        const userOptions = JSON.parse(sessionStorage.getItem("userOptions"))

        if (userOptions != undefined) {
            this.state = {
                isPlaying: false,
                patreonLevel: 0,
                //selectedDeck: props.selectedDeck,//userOptions.selectedDeck,
                options: {
                    removeStrongPermanents: userOptions.options.removeStrongPermanents,
                    startWithPermanent: userOptions.options.startWithPermanent,
                    hordeheals: userOptions.options.hordeheals,
                    hordeHasLifepoints: userOptions.options.hordeHasLifepoints,
                    startLife: userOptions.options.startLife,
                    milestones: userOptions.options.milestones,
                    deckSize: userOptions.options.deckSize,
                    tokenMultiplicator: userOptions.options.tokenMultiplicator,
                    allowTokensHandAndGraveyard: userOptions.options.allowTokensHandAndGraveyard,
                    backgroundColor: userOptions.options.backgroundColor,
                    foilEffect: userOptions.options.foilEffect,
                    alternateDrawMode: userOptions.options.alternateDrawMode,
                    safezoneSize: userOptions.options.safezoneSize,
                    backgroundImageUrl: userOptions.options.backgroundImageUrl,
                    drawPerTurn: userOptions.options.drawPerTurn,
                    mulltiplayerEnabled: userOptions.options.mulltiplayerEnabled,
                    alternateMilestones: userOptions.alternateMilestones,
                    alternateMilestones_shuffle: userOptions.alternateMilestones_shuffle
                },
            }
        } else {
            let cookie = this.readOptionsCookie()
            console.log("Using cookie")
            console.log(cookie)
            if (cookie == null) {
                this.state = {
                    isPlaying: false,
                    patreonLevel: 0,
                    //selectedDeck: props.selectedDeck,
                    options: {
                        removeStrongPermanents: true,
                        startWithPermanent: false,
                        hordeHasLifepoints: false,
                        hordeheals: false,
                        startLife: 60,
                        milestones: [false, false, false, false],
                        deckSize: 100,
                        tokenMultiplicator: 1,
                        allowTokensHandAndGraveyard: false,
                        backgroundColor: 1,
                        foilEffect: [false, false, false],
                        alternateDrawMode: -1,
                        safezoneSize: 1,
                        backgroundImageUrl: "",
                        drawPerTurn: 1,
                        mulltiplayerEnabled: false,
                        alternateMilestones: false,
                        alternateMilestones_shuffle: false
                    }
                }
            } else {
                this.state = {
                    isPlaying: false,
                    patreonLevel: 0,
                    //selectedDeck: props.selectedDeck,
                    options: cookie
                }
            }
        }
    }

    setPatreonLevel = (value) => {
        this.state.patreonLevel = value
    }

    setIsPlaying = (value) => {
        this.setState({ isPlaying: value })
    }

    setSelectedDeck = (deck) => {
        this.setState({ selectedDeck: deck })

        sessionStorage.setItem("userOptions", JSON.stringify({
            options: this.state.options,
            selectedDeck: deck
        }))
    }

    setOptions = (options) => {
        this.setState({ options: options })

        sessionStorage.setItem("userOptions", JSON.stringify({
            options: options,
            selectedDeck: this.state.selectedDeck
        }))

        this.bakeOptionsCookie(options)
    }

    bakeOptionsCookie = (options) => {
        let date = new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 31)
        date = date.toUTCString()
        var cookie = ["options", '=', JSON.stringify(options), /*'; domain=.', window.location.host.toString(), */'; path=/;', 'expires=', date, ";"].join('');
        document.cookie = cookie;
    }

    readOptionsCookie = () => {
        var result = document.cookie.match(new RegExp("options" + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    // OPTIONS CHANGES

    startGame = () => {
        this.setState({ isPlaying: true })
    }

    onChangeRemoveStrongPermanents = () => {
        let options = this.state.options
        options.removeStrongPermanents = !options.removeStrongPermanents
        this.setOptions(options)
    }

    onChangeStartWithPermanents = () => {
        let options = this.state.options
        options.startWithPermanent = !options.startWithPermanent
        this.setOptions(options)
    }

    onChangeHordeHeals = () => {
        let options = this.state.options
        options.hordeheals = !options.hordeheals
        this.setOptions(options)
    }

    onChangeMilestones = (milestone) => {
        let options = this.state.options
        options.milestones[milestone] = !options.milestones[milestone]
        this.setOptions(options)
    }

    onChangeStartLife = (value) => {
        let options = this.state.options
        options.startLife = value
        this.setOptions(options)
    }

    onChangeDeckSize = (value) => {
        let options = this.state.options
        options.deckSize = value
        this.setOptions(options)
    }

    onChangeTokenMultiplicator = (value) => {
        let options = this.state.options
        options.tokenMultiplicator = value
        this.setOptions(options)
    }

    onChangeAllowTokensHandAndGraveyard = () => {
        let options = this.state.options
        options.allowTokensHandAndGraveyard = !options.allowTokensHandAndGraveyard
        this.setOptions(options)
    }

    onChangeBackgroundColor = (value) => {
        let options = this.state.options
        options.backgroundColor = value
        this.setOptions(options)
    }

    onChangeFoilEffect = (value) => {
        let options = this.state.options
        options.foilEffect = value
        this.setOptions(options)
    }

    onChangeAlternateDrawMode = () => {
        let options = this.state.options
        options.alternateDrawMode = options.alternateDrawMode == -1 ? 1 : -1
        this.setOptions(options)
    }

    onChangeSafezoneSize = (value) => {
        let options = this.state.options
        options.safezoneSize = value
        this.setOptions(options)
    }

    onChangeBackgroundImageUrl = (value) => {
        let options = this.state.options
        options.backgroundImageUrl = value
        this.setOptions(options)
    }

    onChangeHordeHasLifepoints = () => {
        let options = this.state.options
        options.hordeHasLifepoints = !options.hordeHasLifepoints
        this.setOptions(options)
    }

    onChangeDrawPerTurn = (value) => {
        let options = this.state.options
        options.drawPerTurn = value
        this.setOptions(options)
    }

    onChangeMultiplayer = (value) => {
        let options = this.state.options
        options.mulltiplayerEnabled = value
        this.setOptions(options)
    }

    onChangeAlternateMilestones = () => {
        let options = this.state.options
        options.alternateMilestones = !options.alternateMilestones
        this.setOptions(options)
    }

    onChangeAlternateMilestones_shuffle = () => {
        let options = this.state.options
        options.alternateMilestones_shuffle = !options.alternateMilestones_shuffle
        this.setOptions(options)
    }

    // RENDER

    render() {
        if (this.state.isPlaying) {
            // AND PATREON
            if (this.state.options.mulltiplayerEnabled && this.state.patreonLevel > 0) {
                return <LandscapeOnly component={<MultiplayerHostGame selectedDeck={this.props.selectedDeck} setIsPlaying={this.setIsPlaying} options={this.state.options} />} />;
            } else {
                return <LandscapeOnly component={<GameReborn selectedDeck={this.props.selectedDeck} setIsPlaying={this.setIsPlaying} options={this.state.options} />} />;
            }
        } else {
            return <div>
                <div class={this.props.selectedDeck != "INTRO" && this.props.selectedDeck ? "playable-component-invisible" : ""}>{this.props.component}</div>
                <div class={this.props.selectedDeck != "INTRO" && this.props.selectedDeck ? "deck-panel" : "deck-panel invisible"}>
                    {this.props.selectedDeck != "INTRO" && this.props.selectedDeck ? (
                        <div class="noScrollbar selected-deck container-fluid h-100 col p-0">
                            {
                                this.props.selectedDeck.image ?
                                    (<div>
                                        <img src={require('./../../../assets/' + this.props.selectedDeck.image)} class="background-image" />
                                    </div>) :
                                    (<div>
                                        <DeckArt artId={this.props.selectedDeck.artId} classes="background-image" />
                                    </div>)
                            }
                            <div class="background"></div>
                            <img class="close-xmark" src={require("./../../../assets/Icons/x.png")} onClick={() => { this.props.setSelectedDeck(false) }} />
                            <LandscapeOnly component={
                                <div class="deck-info text-center row p-0 text-white">

                                    <div class="row position-relative p-0 mx-0 deck-info-container">
                                        <div class="col-7 p-2 d-flex flex-column overflow-hidden justify-content-between">
                                            {/*
                                        this.props.selectedDeck.image ?
                                            (<div>
                                                <img src={require('./../../../assets/' + this.props.selectedDeck.image)} class="background-image"/>
                                                <div class="d-flex flex-column align-items-center mb-5">
                                                    <img src={require('./../../../assets/' + this.props.selectedDeck.image)} class="deck-art"/>
                                                    <BigButton clicked={this.startGame} text="Play" />
                                                </div>
                                                
                                            </div>) :
                                            (<div>
                                                <DeckArt artId={this.props.selectedDeck.artId} classes="background-image" />
                                                <DeckArt artId={this.props.selectedDeck.artId} classes="deck-art" />
                                            </div>)
                                            */}
                                            <h1>{this.props.selectedDeck.name}</h1>
                                            <div class="">
                                                {
                                                    this.props.selectedDeck.intro.trim().length >= 2 ?
                                                        (<div class="info">
                                                            <p>{this.props.selectedDeck.intro}</p>
                                                            <h2>{this.props.selectedDeck.specialRules == "" ? "" : "Special rules"}</h2>
                                                            <p>{this.props.selectedDeck.specialRules}</p>
                                                        </div>) :
                                                        (<div class="info">
                                                            <h2 class="mt-0">{this.props.selectedDeck.specialRules == "" ? "" : "Special rules"}</h2>
                                                            <p>{this.props.selectedDeck.specialRules}</p>
                                                        </div>)
                                                }
                                                <BigButton clicked={this.startGame} text="Play" />
                                            </div>

                                        </div>
                                        <div class="col-5 p-2">
                                            <Options options={this.state.options} functions={{
                                                onChangeRemoveStrongPermanents: this.onChangeRemoveStrongPermanents,
                                                onChangeStartWithPermanents: this.onChangeStartWithPermanents,
                                                onChangeMilestones: this.onChangeMilestones,
                                                onChangeHordeHeals: this.onChangeHordeHeals,
                                                onChangeStartLife: this.onChangeStartLife,
                                                onChangeDeckSize: this.onChangeDeckSize,
                                                onChangeTokenMultiplicator: this.onChangeTokenMultiplicator,
                                                onChangeAllowTokensHandAndGraveyard: this.onChangeAllowTokensHandAndGraveyard,
                                                onChangeBackgroundColor: this.onChangeBackgroundColor,
                                                onChangeFoilEffect: this.onChangeFoilEffect,
                                                onChangeAlternateDrawMode: this.onChangeAlternateDrawMode,
                                                onChangeSafezoneSize: this.onChangeSafezoneSize,
                                                onChangeBackgroundImageUrl: this.onChangeBackgroundImageUrl,
                                                onChangeHordeHasLifepoints: this.onChangeHordeHasLifepoints,
                                                onChangeDrawPerTurn: this.onChangeDrawPerTurn,
                                                onChangeMultiplayer: this.onChangeMultiplayer,
                                                onChangeAlternateMilestones: this.onChangeAlternateMilestones,
                                                onChangeAlternateMilestones_shuffle: this.onChangeAlternateMilestones_shuffle,
                                                setOptions: this.setOptions,
                                                setPatreonLevel: this.setPatreonLevel
                                            }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            } />
                        </div>) : (<div></div>)
                    }
                </div>
            </div>
        }
    }
}

export default PlayablePanel